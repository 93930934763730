.ConfirmAssignSchedule{
    width: 560px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ConfirmAssignSchedule-Btn-Box{
    display: flex;
    gap: 5px;
    justify-content: end;
}