.PreNatalAttendance-Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-bottom: 0.75rem;
}

.PreNatalAttendance-Subtitle {
    margin-top: 1.75rem;
}

.PreNatalAttendance-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.75rem;
    flex-wrap: wrap;
    margin-top: 0.75rem;   
    margin-bottom: 0.75rem;
}

.PreNatalAttendance-Grid-Session {
    display: flex;
    column-gap: 2rem;
}

.PreNatalAttendance-AccordionNav {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),0 -1px rgba(0, 0, 0, 0.1) inset,0 2px 1px -1px rgba(255, 255, 255, 0.5) inset;
    width: 99.9%;
    background-color: #EEE;
    margin-bottom: 1rem;
}

.PreNatalAttendance-AccordionNav > svg {
    width: 12px;
    fill: black;
}

.PreNatalAttendance-AccordionNav.open > svg {
    transform: rotate(180deg);
}

.PreNatalAttendance-Section {
    transition: opacity 0.5s ease, height 0.5s ease;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding-bottom: 1rem;
    border: 1px solid #EEE;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.PreNatalAttendance-Section.open {
    opacity: 1;
    height: auto;
}

.PreNatalAttendance-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-top: 1rem;
}

.PreNatalAttendanceEditor {
    min-width: 600px;
}

.PreNatalAttendanceEditor-FARow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.PreNatalAttendanceEditor-SelectInput {
    display: grid;
    grid-template-columns: 140px 1fr;
    column-gap: 1rem;
}

.PreNatalAttendanceEditor-HU {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.PreNatalAttendanceEditor-HU-H-Input {
    display: grid;
    grid-template-columns: 100px 1fr;
    column-gap: 1rem;
}

.PreNatalAttendanceEditor-Pressure {
    display: grid;
    grid-template-columns: 96px 96px 200px;
    column-gap: 1rem;
}


.PreNatalAttendanceEditor-DateRow {
    display: grid;
    grid-template-columns: 5rem 7.5rem 5rem;
    column-gap: 1rem;
}

.PreNatalAttendanceEditor-PatientComplaints {
    max-height: 120px;
    overflow-y: auto;
}


.PreNatalAttendanceEditor-PatientComplaints-TextEditor {
    color: var(--color-page);
    padding: 12px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid #ccc;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}

.PreNatalAttendanceEditor-BtnBox {
    margin-top: 1rem;
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
}