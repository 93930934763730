.HealthPlaceUserUnitWrapper {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    column-gap: 1rem;
    border: 1px solid #eee;
    max-width: 1000px;
    width: 100%;
    padding: 1rem;
}

.HealthPlaceUserUnitWrapper-Separator {
    background-color: #eee;
    width: 1px;
    height: 100%;
}

.HealthPlaceUserUnitWrapper-HoldingInfo {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
}

.HealthPlaceUserUnitWrapper-User-Status {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
}

.HealthPlaceUserUnitWrapper-User-Status.online {
    background-color: rgb(18, 224, 18);
  }
  
  .HealthPlaceUserUnitWrapper-User-Status.offline {
    background-color: gray;
  }

.HealthPlaceUserUnitWrapper-HoldingInfo-Header {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar {
    display: flex;
    flex-direction: row;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar > .img {
    border: 1px solid #eee;
    border-radius: 50%;
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar > .img > .icon-user {
  font-size: 1.5rem;
  color: var(--primary-bg);
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar-Img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox {
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
}    

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Name {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Warning:hover{
    cursor: pointer;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Warning-Btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-AltText {
    font-size: 0.85rem;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow svg {
    font-size: 0.8rem;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    width: 100%;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-BtnFullWidth button {
	width: 100%;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-HorizontalSeparator {
    background-color: #eee;
    height: 1px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Footer {
    display: flex;
    justify-content: space-between;

}

.HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 12px;
}

.HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle svg {
	cursor: pointer;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
    width: 100%;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-Title{
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-Modal{
    position: relative;
    width: 100%;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-CalendarBtn {
    width: 100%;
    margin: auto;
    cursor: pointer;
    color: white;
    background-color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border: none;
    padding: 0.25rem 0.5rem;
    height: 32px;
    border-radius: 4px;
    position: relative;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-CalendarBtn::after {
    content: "▼";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8rem;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-OpenedModal .HealthPlaceUserUnitWrapper-NextTenAvailable-CalendarBtn::after {
    content: "▲";
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 0.8rem;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-OpenedModal {
    background-color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 1;
    border: 1px solid var(--primary-color);
    width: 100%;
    border-radius: 4px;
  }
  
  .HealthPlaceUserUnitWrapper-NextTenAvailable-ClosedModal {
    display: none;
    opacity: 0;
  }

.HealthPlaceUserUnitWrapper-NextTenAvailable-Empty-Message{
    color: #aaa;
    margin: auto;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-NoCalendar{
    color: #aaa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-Unit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background-color: #E3F0FF;
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: var(--primary-color);
    text-align: center;
    width: 100%;
    margin: auto;
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-Unit:hover {
    border: 1px solid var(--primary-color);
}

.HealthPlaceUserUnitWrapper-NextTenAvailable-Btn {
    cursor: pointer;
    font-weight: bold;
    border: none;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #E3F0FF;
    padding: 0.15rem;
}