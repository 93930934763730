.HoldingPatientInfoView-Header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 1rem;
}

.HoldingPatientInfoView-Header-Profile {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.HoldingPatientInfoView-Header-Profile-Avatar {
  display: flex;
  flex-direction: row;
}

.HoldingPatientInfoView-Header-Profile-Avatar > .img {
  border: 1px solid #eee;
  border-radius: 50%;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.HoldingPatientInfoView-Header-Profile-Avatar > .img > .icon-user {
font-size: 1.5rem;
color: var(--primary-bg);
}

.HoldingPatientInfoView-Header-Profile-Avatar-Img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.HoldingPatientInfoView-Header-Profile-PatientInfoBox {
  display: flex;
  flex-direction: column;
  row-gap: 0.1rem;
}    

.HoldingPatientInfoView-Header-Profile-PatientInfoBox-Name {
  text-transform: uppercase;
}

.HoldingPatientInfoView-Header-Profile-PatientInfoBox-AltText {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 8px;
}

.HoldingPatientInfoView-Header-Profile-PatientInfoBox-DuplicateFlag{
  color: red;
  font-size: 15px;
  padding: 5px;
  background-color: rgb(255, 192, 192);
  border: red 1px solid;
  border-radius: 5px;
  margin-left: 15px;
}

.HoldingPatientInfoView-TabManager {
	display: grid;
	grid-template-columns: 160px 160px 160px 160px 1fr;
  border-bottom: 1px solid #eee;
}

.HoldingPatientInfoView-TabManager-btn {
	width: 100%;
	display: flex;
	font-size: 0.8rem;
	cursor: pointer;
	color: rgb(28, 30, 33);
	height: 40px;
	align-items: center;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	color: #333333;
}

.HoldingPatientInfoView-TabManager-btn:hover {
	border-bottom: 1px solid var(--primary-color);
}

.HoldingPatientInfoView-TabManager-btn.active {
	color: var(--primary-color);
	fill: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}

.HoldingPatientInfoView-Content {
  margin-top: 1rem;
  height: 100%;
  min-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
