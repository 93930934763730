.Appointments-Info {
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
}

.Appointments-Statistic {
    width: 30%;
    padding: 1rem 1rem 0;
}

.NextAppointments {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0.5rem 0 ;
}

.Appointments-Info-Header-Title {
    font-size: 1.5rem;
    font-weight: 600;
}

.Appointments-Info-Container {
    margin-top: 1rem;
    display: flex;
    height: 100%;
    /* width: 100%; */
    /* justify-content: space-between; */
}

/* Appointments Summary Chart*/
/* .Appointments-Summary-Chart {
    height: 100%;
    width: 100%;
    padding: 0.75rem;
}

.Appointments-Summary-Chart-Header-Title  {
    font-size: 1.25rem;
    font-weight: 600;
}

.Appointments-Summary-Info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 0.5rem;
}

.Appointments-Summary-Info-Item {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;

}

.Appointments-Summary-Info-Item > span {
    font-weight: 500;
    margin-left: 0.5rem;
    margin-right: 0.25rem;
} */

/* Next Appointments Chart */
.NextAppointments-Chart {
    padding: 1.25rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    width: 100%;
    height: 360px;
    box-shadow: 2px -2px 10px 1px rgba(80, 80, 80, 0.2);


}

.NextAppointments-Charts-Header {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
}

.NextAppointments-Charts-Header-Title {
    font-size: 1.25rem;
    font-weight: 600;
}

.NextAppointments-Charts-Header-NumberBox {
    width: 1.8rem;
    height: 1.8rem;
    background-color: var(--primary-color);
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NextAppointments-Charts-Header-NumberBox>p {
    margin: 0;
}

.NextAppointments-Container {
    display: flex;
    flex-direction: column;
    gap: 0.325rem;
    height: calc(100% - 40px);
    overflow-y: scroll;

}

.Appointment-Item {
    position: relative;
    padding: 0.5rem;
    border: 1px solid #d4d4d4;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.Appointment-Item-Info {
    padding: 0 0.5rem;
    width: 100%;
}

.Appointment-Item-PatientInfo {
    font-size: 1.05rem;
    font-weight: 600;
}


.Appointment-Item-AttendanceInfo {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--primary-color);
}

.Appointment-Item-AttendanceInfo-Item {
    border-left: 1px solid;
    width: 150px;
    text-align: center;
}

.Appointment-Item-TimeScheduled-Item {
    display: flex;
    padding-right: 1.625rem;
}

.Appointment-Item-TimeScheduled-Item>p {
    padding: 0 5px;
}

.Appointment-Item-TimeScheduled-IcoBox {
    display: flex;
    align-items: center;

}

.Appointment-Item-Info p {
    margin: 0;
}


.Appointment-Item-IconBox {
    height: 2rem;
    width: 2rem;
    /* background-color: rgb(255, 255, 255, 0.2); */
    background-color: var(--primary-color);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Appointment-Item-AttendanceInfo-Status {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0 0.25rem;
    border-radius: 5px;
}

/* NoAppointments Container  */
.NoNextAppointments-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.NoNextAppointments-Message {
    font-size: 1.125rem;
    font-weight: 600;
    color: #aaa;
}

/* ScrollBar Style */
.NextAppointments-Container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.NextAppointments-Container::-webkit-scrollbar-track {
    background: transparent;
}

.NextAppointments-Container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.NextAppointments-Container::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}