:root {

  --primary-color: #005daa;
  --primary-color-light: #0083ef;
  --primary-color-lighter: #0d8ffb;
  --primary-color-dark: #004a88;
  --primary-color-darker: #003969;
  --primary-color-light-2: #bbe0ff;
  
  --primary-font-color: #ffffff;
  
  --primary-bg: var(--primary-color);
  --primary-bgh: var(--primary-color);

  --secondary-bg: #BBBBBB;
  --secondary-bgh: #a5a4a4;
  --secondary-color: #fff;

  --success-bg: #58CCBF;
  --success-bgh: #46b8aa;
  --success-color: #fff;

  --error-bg: #F95C5C;
  --error-bgh: #FF2F22;
  --error-color: #fff;

  --warning-bg: #f8b24a;
  --warning-bgh: #d5932f;
  --warning-color: #fff;

  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: #58CCBF;
  --scrollbar-thumbh: #46b8aa;

  --background-page: #f1f1f1;
  --color-page: #333333;
  --background-container: #fff;

  --background-header: var(--primary-color);
  --background-header-h: #39afa1;
  --color-header: #fff;

  --background-sidebar: #fff;
  --background-sidebar-h: var(--primary-color);
  --color-sidebar: #282828;
  --color-sidebar-h: #fff;

  --background-footer: var(--primary-color);
  --background-footer-h: #39afa1;
  --color-footer: #fff;

  --input-bg: #fff;
  --input-bgh: #bbb;
  --input-color: #555555;

  --input-bgh-success: #58CCBF;
  --input-color-success: #555555;

  --input-bgh-error: #F95C5C;
  --input-color-error: #555555;

  --list-bg: #f1f1f1;
  --list-bgh: #f3f3f3;
  --list-color: #333;

  --modal-bg: #fff;
  --modal-bgo: #000000c9;
  --modal-bgh: #bbb;
  --modal-color: #333333;


  --ocupado-bg: #FF857D;
  --ocupado-bgh: #FF857D;
  --ocupado-color: #555555;

  --disponivel-bg: #AAF290;
  --disponivel-bgh: #AAF290;
  --disponivel-color: #555555;

  --compromisso-bg: #65CEFE;
  --compromisso-bgh: #65CEFE;
  --compromisso-color: #555555;

  --atual-bg: #58CCBF;
  --atual-bgh: #58CCBF;
  --atual-color: #555555;

  --light-borders: #eee;

}

/* 6ac9c7 */
