.activate-user-modal {
	text-align: center;
	width: 400px;
}

.activate-user-warning {
	color: indianred;
	font-size: 18px;
	font-weight: bold;
	padding: 20px 0;
}

.activate-user-confirmation {
	font-weight: bold;
	padding: 20px 0;
}