.toggle {
    position: relative;
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    --toggle-height: 18px;
    --toggle-width: 38px;
    --toggle-circle-size: calc(var(--toggle-height) - 2px);
}

.toggle-label {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    bottom: 2px;
}

.toggle-trail {
    height: var(--toggle-height);
    width: var(--toggle-width);
    background-color: #ddd;
    border-radius: 15px;
    margin: 0 10px 0 0;
    transition: background-color ease 200ms;
}

.toggle-circle {
    height:var(--toggle-circle-size);
    width: var(--toggle-circle-size);
    background-color: white;
    border-radius: 50px;
    position: relative;
    top: 1px;
    left: 1px;
    transition: left ease 200ms;
}
.toggle.on .toggle-circle {
    position: relative;
    left: calc(var(--toggle-width) - var(--toggle-circle-size) - 1px)
}
.toggle.on .toggle-trail {
    background-color: var(--primary-color);
}

