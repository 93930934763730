.NewChatModal {
    width: 400px;
    z-index: 9999;
}

.NewChatModal-ParticipantsList-Label {
    font-weight: 600;
}

.NewChatModal-ParticipantsList {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
    margin: 15px 0 15px 0;
    max-height: 150px; 
    overflow-y: auto; 
    box-sizing: border-box;
    margin-right: 1rem;
}

.NewChatModal-ParticipantsList-Item {
    padding: 0.3rem 1.8rem 0.3rem 0.8rem ;
    /* background-color: #fff; */
    border: none;
    border-radius: 16px;
    /* cursor: pointer; */
    background-color: #ddd;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.NewChatModal-ParticipantsList-Item-IconBox{
    display: flex;
    align-items: center;
    justify-items: center;
    position: absolute;
    right: 5px;
    width: 1rem;
    cursor: pointer;

}

.NewChatModal-ParticipantsList-Item-CloseIcon:hover {
    color: #aaa;
}

.NewChatModal-ActionBox {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
    justify-content: center;
}

/* ScrollBar Style */
.NewChatModal-ParticipantsList::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.NewChatModal-ParticipantsList::-webkit-scrollbar-track {
    background: transparent; 
}

.NewChatModal-ParticipantsList::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.NewChatModal-ParticipantsList::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}