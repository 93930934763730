.NewPaginate {
  display: flex;
  align-items: center;
  justify-content: center; 
  column-gap: 1rem;
}

.NewPaginate button {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  cursor: pointer;
  font-size: 0.9rem;
}

.NewPaginate button:hover {
  background-color: #eee;
}

.NewPaginate button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
