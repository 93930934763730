.box-hidden {
  margin-bottom: 20px;
  max-height: 60em;
  overflow-x: auto;
  overflow-y: hidden;
}
.list-box {
  border-top: 1px solid var(--list-bgh);
  border-spacing: 0;
  color: var(--list-color);
  position: relative;
  width: 100%;
}
.list-box td {
  border-bottom: 1px solid var(--list-bgh);
  padding: 2px 10px;
}

.list-box thead {
  border-radius: 12px 12px 0px 0px;
}
.list-box thead td {
  background-color: var(--list-bgh);
  padding: 8px 10px;
  font-weight: bold;
}

.list-box tbody tr:hover td {
  background-color: var(--list-bg);
}
.list-box .actions {
  white-space: nowrap;
  width: 1%;
}

.list-box .actions button {
  margin-left: 10px;
}
.list-box .actions button:first-child {
  margin-left: 0px;
}
.list-box .text-center{
  text-align: center;
}

.list-box tbody {
  display: block;
}

.list-box thead, .list-box tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.list-box tbody tr {
  min-height: 40px;
}

.list-normal-row {
  word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

.list-action-row > div {
  display: flex;
  column-gap: 0.5rem;
}

.list-box {
  width: 100%;
}