.NewEditElimination {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 500px;
}

.NewEditElimination-BtnBox {
    display: flex;
    justify-content: flex-end;
}

.NewEditElimination-Grid {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
}

.NewEditElimination-Select .SelectBox, .NewEditElimination-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.NewEditElimination-Select div {
    width: 160px;
}