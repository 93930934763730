.PersonalDataCouncilManagerModal {
    width: 600px;
    min-height: 200px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
}



.PersonalDataCouncilManagerModal-Header-ActionRow {
	margin: 0rem 0rem 1.75rem;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.PersonalDataCouncilManagerModal-Header-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
}

.PersonalDataCouncilManagerModal-Header-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.PersonalDataCouncilManagerModal-Header-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.PersonalDataCouncilManagerModal-Header-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.PersonalDataCouncilManagerModal-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    column-gap: 0.75rem;
}

.PersonalDataCouncilManagerModal-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.PersonalDataCouncilManagerModal-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}
