.HomePage-Header {
    background-color: var(--primary-color);
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    /* grid-template-columns: 8fr 2fr; */
    column-gap: 0.5rem;
    padding: 0.75rem 1rem;
}

.HomePage-Greetings {
    color: #fff;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.HomePage-Header-Slot {
    /* margin provisorio */
    margin-right: 2rem;
    height: 100%;
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 0.5rem;
    align-items: center;
    color: var(--primary-font-color)
}

.HomePage-Header-Slot-Info span {
    color: rgb(var(--primary-font-color), 0.8);
}

.HomePage-Header-Slot-IconBox {
    height: 48px;
    background-color: rgb(255, 255, 255, 0.2);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}