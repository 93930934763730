.ConflictingEventsModal {
}

.ConflictingEventsModal-Error {
    margin-bottom: 0.5rem;
    color: rgb(236, 106, 106);
    white-space: wrap;
    width: 400px;
    margin-bottom: 1.5rem;
    font-size: 12px;
}

.ConflictingEventsModal-TimeRow {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.ConflictingEventsModal-TimeRow > div {
    white-space: nowrap;
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.25rem;
}

.ConflictingEventsModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}