.HealthPlaceInventoryConfigModal-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1.25rem;
    align-items: center;
}

.HealthPlaceInventoryConfigModal-Header-Icon {
    -webkit-box-align: center;
    background-color: #05668D;
    border-color: #05668D;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.HealthPlaceInventoryConfigModal-Header-Title {
    font-weight: 600;
}

.HealthPlaceInventoryConfigModal-Content {
    display: grid;
    grid-template-columns: 184px 184px 184px;
    column-gap: 1rem;
    row-gap: 0rem;
}

.HealthPlaceInventoryConfigModal-Select .SelectBox, .HealthPlaceInventoryConfigModal-Select .form-box, .HealthPlaceInventoryConfigModal-Input .form-box {
    padding-top: 4px;
}

.HealthPlaceInventoryConfigModal-BtnBox {
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
}