.UpdateFile {
    cursor: pointer;
}

.file-input {
    cursor: pointer;
    position: relative;
}

.file-input__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
}

.UpdateFile-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    height: 44px;
    min-width: 150px;
    padding: 8px 16px;
    text-align: center;
    font-size: 13.33px;
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    background-color: var(--primary-color);
}