.MedicalRecordFilters-Separator {
	width: 100%;
	min-height: 1px;
	background-color: #eee;
	margin: 12px 0;
}

.MedicalRecordFilters-SideBarLeft {
	display: flex;
	flex-direction: column;
}

.MedicalRecordFilters-row {
    display: flex;
    flex-direction: row;
  }

.MedicalRecordFilters-input {
	margin-right: 10px;
}

.MedicalRecordFilters-Select-Date {
	display: grid;
	column-gap: 4px;
	grid-column-gap: 4px;
	max-width: 100%;
	grid-template-columns: 80px 112px 80px;
}