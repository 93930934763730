.DeleteChatModal {
    width: 400px;
    padding: 1rem;
    box-sizing: border-box;
}

.DeleteChatModal p {
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    color: rgb(66, 66, 66);
    margin-bottom: 1rem;
}

.DeleteChatModal-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}
