.button {
    min-width: 150px;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    font-weight: 600;
    height: 44px;
}

.button-span-label {
    white-space:nowrap;
}

.button.primary {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--primary-font-color);
}

.button.primary:hover {
    background-color: var(--primary-color-dark);
    color: var(--primary-font-color)
}

.button.primary:active {
    background-color: var(--primary-color-darker);
}

.button.secondary {
    border: 1px solid #eee;
    background-color: #eee;
    color: #444;
}

.button.secondary:hover {
    background-color: #ddd;
}

.button.secondary:active {
    background-color: #bbb;
}

.button.secondary-bg {
    background-color: var(--secondary-bg);
    border-color: var(--secondary-bgh);
    color: var(--secondary-color);
}
.button.secondary-bg:hover {
    background-color: var(--secondary-bgh);
}

.button.outlined {
    background-color: transparent;
    color: var(--primary-color);
}

.button.warning.outlined {
    border: 1px solid tomato;
    color: tomato;
}

.button.warning.outlined svg {
    fill: tomato;
}

.button.danger {
    background-color: var(--error-bg);
    border-color: var(--error-bgh);
    color: var(--error-color);
}

.button.round-icon {
    --size: 25px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    padding: 0;
    font-size: 20px;
    position: relative;
}

.button.round-icon .icon {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}



.button.danger:hover {
    background-color: var(--error-bgh);
}

.button.disabled {
    border: 1px solid #ddd;
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
}
.button.disabled:hover {
    background-color: #ddd;
}

.button-container {
    position: relative;
}

.button-container .icon {
    position: absolute;
    top: 10px;
    left: 10px;
}

.button-container .icon svg {
    fill:white;
}

.button-container.with-icon span {
    display: inline-block;
    padding-left: 18px;
}

@media screen and (max-width: 768px) {
    .button {
        min-width: 75px;
    }
  }