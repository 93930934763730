.info-line {
    /* display: flex; */
    width: 100%;
    margin: 5px auto;
    padding: 4px 0;
}

.info-line * {
    flex: 1;
}

.info-line label {
    text-align: left;
    padding-right: 2px;
    font-weight: bold;
}

.info-line span {
    text-align: left;
    padding: 2px 0;
    flex: 1;
    display: block
}