.OphthalmologyTemplate {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    column-gap: 5rem;
    flex-wrap: wrap;
}

.OphthalmologyTemplate-Notes {
    margin-top: 2.5rem;
}