.VideoCallScreen {
  background-color: rgb(32, 33, 36);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.VideoCallScreen-Loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 2rem;
}

.VideoCallScreen-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding:8px;
}

.VideoCallScreen-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.VideoCallScreen-Local > video {
  border-radius:4px;
  width: 730px;
}

.VideoCallScreen-Container.twoParticipants > .VideoCallScreen-Local > video {
    width: 150px;
    position: absolute;
    margin-left: calc(730px - 150px);
}

.VideoCallScreen-Container.twoParticipants > .VideoCallScreen-Remote {
    width: 730px;
    height: 550px;
    display: flex;
    justify-content: center;
}

.VideoCallScreen-Container.twoParticipants > .VideoCallScreen-Remote > video {
    height: 100%;
}

.VideoCallScreen-Footer {
    position: relative;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.VideoCallScreen-Footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.VideoCallScreen-Footer svg {
  width: 24px;
  height: 24px;
  color: white;
}

.VideoCallScreen-Footer-Btn {
    background-color: #3C4043;
}

.VideoCallScreen-Footer-EndBtn {
    background-color: red;
}


.VideoCallScreen-BtnSlash:after {
  content: '';
  border-bottom: 2px solid var(--primary-color);
  border-top: 2px solid white;
  width: 30px;
  
  transform: rotate(60deg);
}

@media (max-width: 1024px) {
  .VideoCallScreen-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .VideoCallScreen-Local > video {
    border-radius: 4px;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }

  .VideoCallScreen-Container.twoParticipants > .VideoCallScreen-Local > video {
    height: 120px;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: unset;
    height: unset;
    object-fit: unset;
    object-position: unset;
  }

  .VideoCallScreen-Container.twoParticipants > .VideoCallScreen-Remote {
    border-radius: 4px;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }
}