.Confirm-Notificate-Modal{
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Confirm-Notificate-Modal-Button-div{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}