.RecallTicketModal {
    width: 300px;
}

.RecallTicketModal-P {
    margin-bottom: 1rem;
}
.RecallTicketModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}