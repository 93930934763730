.DownloadDocumentProgressBarModal {
    width: 400px;
    
}

.DownloadDocumentProgressBarModal-upload-progress {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 1rem;
}

.DownloadDocumentProgressBarModal-progress-bar {
    height: 1rem;
    background-color: var(--primary-color);
    text-align: center;
    color: white;
    line-height: 1rem;
    border-radius: 4px;
    transition: width 0.4s ease;
}

.DownloadDocumentProgressBarModal-Asterisk {
    color: rgb(236, 106, 106);
}