.DocumentsEmission-TabManager {
	display: grid;
	grid-template-columns: 160px 160px 160px 160px 1fr;
    border-bottom: 1px solid #eee;
}

.DocumentsEmission-TabManager-btn {
	width: 100%;
	display: flex;
	font-size: 0.8rem;
	cursor: pointer;
	color: rgb(28, 30, 33);
	height: 40px;
	align-items: center;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	color: #333333;
}

.DocumentsEmission-TabManager-btn:hover {
	border-bottom: 1px solid var(--primary-color);
}

.DocumentsEmission-TabManager-btn.active {
	color: var(--primary-color);
	fill: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}

.DocumentsEmission-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.DocumentsEmission-Body {
    height: 600px;
    margin-top: 1rem;
}

.DocumentsEmission-MessageBox {
	display: flex;
	flex-direction: column;
}

.DocumentsEmission-Message-Title {
	padding-bottom: 10px;
}

.DocumentsEmission-Message-ButtonBox {
	display: flex;
	gap: 5px;
}