.VerifyEmailRegister {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 450px;
    background-color: var(--background-container);
}

.VerifyEmailRegister > section > h3 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-bg);
}

.VerifyEmailRegister section {
    max-width: 400px;
    width: 100%;
    border: rgb(219, 219, 219);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 4rem 2rem 2rem;
    position: relative;
    background-color: var(--background-container);
    border-radius: 4px;
}

.VerifyEmailRegister-Headline {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VerifyEmailRegister-Headline > h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.VerifyEmailRegister-Headline > div {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    background-color: rgb(13, 143, 251, 0.1);
    width: fit-content;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.VerifyEmailRegister-SendBtn {
    cursor: pointer;
    color: var(--primary-color);
}

.VerifyEmailRegister-SendBtn:active {
    color: rgb(13, 143, 251, 0.1);
}

.VerifyEmailRegister-BtnBox {
    margin-top: 2rem;
}

.VerifyEmailRegister-BtnBox div button {
    width: 100%;
}

@media (max-width: 576px) {
    .VerifyEmailRegister section {
        border: unset;
        box-shadow: unset;
    }
}