.SOAP-ThousandDaysExamSubSection-Subtitle {
    font-weight: 900;
    font-size: 0.95rem;
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color);
}

.SOAP-ThousandDaysExamSubSection-ExamSelection {
    width: 100%;
}

.SOAP-ThousandDaysExamSubSection-Modal {
    min-width: 480px
}

.AddNewExamModal-ButtonRow {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.25rem;
    column-gap: 0.5rem;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-Tracking {
    margin-top: 1rem;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.25rem;
    row-gap: 0.5rem;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingBar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
    border-radius: 1rem;
    height: 12px;
    width: 100%;
    cursor: pointer;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingBar > div {
    background-color: var(--primary-color-light-2);
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingBar > div.active {
    background-color: var(--primary-color);
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingBar > div:hover {
    background-color: var(--primary-color);
}

.SOAP-ThousandDaysExamSubSection-ExamSelection-Select {
    width: 440px;
}

.SOAP-ThousandDaysExamSubSection-View-SideLeft-btn {
    display: flex;
    font-size: 0.88rem;
    cursor: pointer;
    color: rgb(28, 30, 33);
    align-items: center;
    padding: 1rem 0rem 1rem 0.5rem;
    border-left: 3px solid white;
    font-weight: 400;
}

.SOAP-ThousandDaysExamSubSection-View-SideLeft-btn:hover {
    border-left: 3px solid #eee;
}

.SOAP-ThousandDaysExamSubSection-View-SideLeft-btn.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    font-weight: 700;
    border-left: 3px solid var(--primary-color);
}

.SOAP-ThousandDaysExamSubSection-View {
    display: grid;
    grid-template-columns: 180px 1fr;
    column-gap: 1.5rem;
}

.SOAP-ThousandDaysExamSubSection-View-Btn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}