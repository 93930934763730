.DentistAppointmentAssessment-TextAreaWrapper {
    display: grid;
    grid-template-columns: 132px 1fr;
    column-gap: 1rem;
    margin-top: 1rem;
}

.DentistAppointmentAssessment-TeethMap {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 1.5rem;
    row-gap: 1rem;
}

.DentistAppointmentAssessment-SelectGrid {
    display: grid;
    grid-template-columns: 300px 300px; 
    column-gap: 1rem;
}

.DentistAppointmentAssessment-TeethRow {
    display: grid;
    grid-template-columns: repeat(8, min-content);
    column-gap: 0.5rem;
}

.DentistAppointmentAssessment-Separator {
    height: 1px;
    background-color: #EEE;
    width: 100%;
    margin: 1.5rem 0rem;
}

.DentistAppointmentAssessment-ToothInstance-SelectOptions {
    width: 100%;
    margin: 0rem 0 1rem;
    padding: 0.5rem 0.25rem;
    cursor: pointer;
}

.DentistAppointmentAssessment-ToothInstance {
    width: min-content;
    cursor: pointer;
}

.DentistAppointmentAssessment-ToothInstance-Str {
    text-align: center;
}

.DentistAppointmentAssessment-ToothInstance > svg {
    fill: var(--secondary-bg);
}

.DentistAppointmentAssessment-ToothInstance > p {
    color: var(--secondary-bg);
}

.DentistAppointmentAssessment-ToothInstance > svg:hover {
    fill: var(--text-color);
    cursor: pointer;
}

.DentistAppointmentAssessment-ToothInstanceActive > svg {
    fill: var(--text-color);
}

.DentistAppointmentAssessment-ToothInstanceActive > p {
    color: var(--text-color);
}

.DentistAppointmentAssessment-ToothInstance-BtnRow {
    display: flex;
    justify-content: flex-end;
}

.DentistAppointmentAssessment-ToothInstance-Textarea {
    color: var(--color-page);
    padding: 12px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid #ccc;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}