.DeleteScheduleSlot {
    width: 600px;
    height: 400px;
}

.DeleteScheduleSlot-TimeRow {
    display: flex;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    height: 300px;
    overflow-y: auto;
}

/* ScrollBar Style */
.DeleteScheduleSlot-TimeRow::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.DeleteScheduleSlot-TimeRow::-webkit-scrollbar-track {
    background: transparent;
}

.DeleteScheduleSlot-TimeRow::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.DeleteScheduleSlot-TimeRow::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.DeleteScheduleSlot-TimeRow > div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.25rem;
}

.DeleteScheduleSlot-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}