.VitalSigns-ActionRow {
	margin: 0rem 0 1.5rem;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.VitalSigns-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
}

.VitalSigns-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.VitalSigns-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.VitalSigns-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}
