.AddStudent {
    width: 400px;
    height: 280px;
    display: flex;
    flex-direction: column;
}

.AddStudent-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-top: auto;
}

.AddStudent-DropdownBox {
    background-color: var(--background-container);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 4px;
    overflow: auto;
    position: absolute;
    margin-top: 0.5rem;
    width: 100%;
    z-index: 3;
}

.AddStudent-DropdownBox-SelectOptions {
    position: relative;
    overflow-y: auto;
    max-height: 280px;
}

.AddStudent-DropdownBox-SelectOptions > div {
    color: var(--input-color);
    cursor: pointer;
    padding: 0.75rem;
    position: relative;
    font-size: 0.9rem;
    border-top: 0.05px solid #eee;
}

.AddStudent-DropdownBox-SelectOptions > div:hover {
    background-color: rgb(238, 238, 238, 0.5);
    padding-left: 1rem;
}

.AddStudent-DropdownBox-SelectOptions > div.selected {
    background-color: rgb(238, 238, 238, 0.5);
    font-weight: 600;
}

.AddStudent-DropdownBox-SelectOptions > div:active {
    position: relative;
}

.AddStudent-DropdownBox-SelectOptions > div {
    align-items: center;
    display: flex;
}

.AddStudent-SelectedStudent {
    margin-top: 1rem;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 1rem;
}

.AddStudent-SelectedStudent:hover {
    border: 1px solid #bbb;
    cursor: pointer;
}