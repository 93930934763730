.NewAppointmentModal-Grid {
    display: grid;
    grid-template-columns: 400px min-content 500px;
    height: 480px;
    column-gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
}

.NewAppointmentModal-Separator {
    width: 1px;
    background-color: #eee;
}

.NewAppointmentModal-2FRRow {
    display: flex;
    width: 100%;
    column-gap: 0.5rem;
}

.NewAppointmentModal-2FRRow > div {
    width: 100%;
}

.NewAppointmentModal-NewSelect {
    padding: 0rem 0.25rem;
}

.NewAppointmentModal-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}
