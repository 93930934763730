.PersonalData {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 500px;
}

.PersonalData-InputsContainerCPFContact {
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.PersonalData-InputsContainerBirthday {
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr 1.5fr 1fr;
}

.PersonalData-Submit-Box{
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}

.PersonalData-Btn-Box {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
}

.Label-Mandatory-Filling{
  color: rgb(236, 106, 106);
  margin-top: 4px;
}

@media (max-width: 576px) {

  .PersonalData-InputsContainerBirthday {
      grid-template-columns: 100px 120px 100px;
  }
}