.DocumentsHistory-subtitle {
    font-size: bold;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.DocumentsHistory-TitleInput {
    border: none;
    width: 200px;
}

.DocumentsHistory-page {
    display: grid;
    grid-template-columns: min-content min-content 1fr;
    column-gap: 1rem;
}

.DocumentsHistory-NewCheckbox > form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

}
.DocumentsHistory-NewCheckbox > form > div {
    width: fit-content;
}


.DocumentsHistory-Separator {
    width: 1px;
    background-color: #eee;
}

.DocumentsHistory-Body {
    width: 100%;
}

.DocumentsHistory-Body-Btn {
    margin-bottom: 1rem;
}

.DocumentsHistory-Body-Wrapper-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    width: min-content;
    white-space: nowrap;
    margin-bottom: 0.5rem;
}

.DocumentsHistory-Body-Wrapper-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.DocumentsHistory-Body-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 560px;
}

.DocumentsHistory-Loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.DocumentsHistory-ActionRow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
}

.DocumentsHistory-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.DocumentsHistory-ActionRow-IsSigned > div > svg:hover, .DocumentsHistory-ActionRow-IsSigned > svg {
    fill: goldenrod !important;
}

.DocumentsHistory-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.DocumentsHistory-Filters-ActionRow {
	margin: 1rem 0 1.25rem;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.DocumentsHistory-Filters-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
}

.DocumentsHistory-Filters-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.DocumentsHistory-Filters-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.DocumentsHistory-Filters-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}