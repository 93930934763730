.ObstetricUltrasonographyWrite-UltrasoundBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ObstetricUltrasonographyWrite-UltrasoundDate {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 80px 120px 80px;
}


.ObstetricUltrasonographyWrite-PlacentaLiqAmn {
    display: grid;
    grid-template-columns: 160px 1fr;
    column-gap: 1rem;
}