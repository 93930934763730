.box-container-body-header-chat-btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.box-container-body-header-chat-btn {
    fill: var(--primary-font-color);
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.2);
    border: none;
    padding: 8px;
    border-radius: 0.5rem;
    position: relative;
}

.box-container-body-header-chat-btn:hover {
    outline: 1px solid white;
}

.box-container-body-header-chat-notification {
    display: flex;
    justify-content: center;
    width: 1.1rem;
    height: 1.1rem;
    background-color: var(--warning-bg);
    border-radius: 50px;
    position: absolute;
    top: -5px;
    right: -5px;
    /* border: 1px solid var(--primary-color); */
}
