.TimeSchedule-Header {
    display: flex;
    column-gap: 0.5rem;
}

.TimeSchedule-ZoomControls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* margin-bottom: 10px; */
    padding-right: 15px;
}

.TimeSchedule-ZoomControls button {
    padding: 0px 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
}


.TimeSchedule-CreateHour-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.5rem;
    border-radius: 4px;
    width: min-content;
    text-wrap: nowrap;
}

.TimeSchedule-CreateHour-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.TimeSchedule-CreateHour-Btn svg {
    width: 16px;
    height: 16px;
}

.TimeSchedule-Body {
    /* margin-top: 0.5rem; */
}

.TimeSchedule-Body-Header {
    display: grid;
    grid-template-columns: min-content 1fr;
}

.TimeSchedule-Body-Header-CalendarRow {
    color: #bbb;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.TimeSchedule-Body-Header-CalendarRow>div {
    cursor: pointer;
    font-size: 14px;
}

.TimeSchedule-Body-Header-CalendarRow .active>div {
    color: var(--primary-color);
}

.TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    display: flex;
    font-weight: 500;
}

.TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek.active {
    color: var(--primary-color);
}

.TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek:hover {
    color: #aaa;
}

.TimeSchedule-Body-Header-Arrow:hover {
    color: #aaa;
}

.TimeSchedule-Body-Header-Cursor {
    display: flex;
}

.TimeSchedule-Body-Header-Cursor-AllCheckBox {
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding-bottom: 8px;
}

.TimeSchedule-Body-Content {
    display: grid;
    grid-template-columns: 20px 52px min-content 1fr;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 250px);
}

.TimeSchedule-Body-HourLabels {
    display: grid;
    /* grid-template-rows: repeat(24, 96px); */
}

.TimeSchedule-Body-HourLabels>div {
    color: #aaa;
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 0;
    padding-right: 0.5rem;

}

.TimeSchedule-Body-Spliter {
    display: grid;
    /* grid-template-rows: repeat(24, 96px); */
}

.TimeSchedule-Body-Spliter>div {
    height: 1px;
    width: 12px;
    background-color: #ddd;
}

.TimeSchedule-Body-HoursGrid {
    display: grid;
    /* grid-template-rows: repeat(24, 96px); */
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}


.TimeSchedule-Body-HoursGrid>div {
    border-top: 1px solid #ddd;
}

.TimeSchedule-Body-CheckBoxGrid {
    display: grid;
    /* grid-template-rows: repeat(24, 96px); */
    justify-content: center;
    align-items: center;
}