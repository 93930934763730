.HealthPlaceLegalDataModal {
    width: 900px;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.HealthPlaceLegalDataModal-Asterisk {
    color: rgb(236, 106, 106);
}

.HealthPlaceLegalDataModal-2Col-Form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.HealthPlaceLegalDataModal-Select .SelectBox, .HealthPlaceLegalDataModal-Select .form-box, .HealthPlaceLegalDataModal-Input .form-box {
    padding-top: 4px;
}

.HealthPlaceLegalDataModal-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 0.5rem;
    align-items: center;
}

.HealthPlaceLegalDataModal-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: rgb(115, 127, 140);
    border-color: rgb(174, 182, 192);
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.HealthPlaceLegalDataModal-Subtitle-Header-Icon-Blue {
    background-color: #104F55;
    border-color: #104F55;
}

.HealthPlaceLegalDataModal-Subtitle-Header-Title {
    font-weight: 600;
}

.HealthPlaceLegalDataModal-Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
}

  
.HealthPlaceLegalDataModal-SaveBtn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}