.TableChronicCondition-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    column-gap: 0.5rem;
}

.TableChronicCondition-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.TableChronicCondition-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.TableChronicCondition-HeaderRow-Action {
    text-align: right;
}

.TableChronicCondition-Body-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TableChronicCondition-Legend {
    color: #bbb;
}

.TableChronicCondition-Pagination {
    margin-top: 0.75rem;
}