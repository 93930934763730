.TreponemalTestView > div {
    display: grid;
    grid-template-columns: min-content;
    column-gap: 2rem;
    row-gap: 2rem;
    display: flex;
}

.TreponemalTestView > div > .list {
    width: 100%;
}
.TreponemalTestView > div > .list > .list-headers {
    background-color: #eee;
    
}

.TreponemalTestView > div > .list > .list-headers > .header {
    color: #333333;
}