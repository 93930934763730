.AttachDocumentSection {
    width: 600px;
}

.AttachDocumentSection-DocumentDate {
    width: 180px;
}

.AttachDocumentSection-DocumentDate .form-box {
    padding-top: 4px;
}

.AttachDocumentSection-container-description {
    color: #6a6b76;
}
  
.AttachDocumentSection-upload-area {
    cursor: pointer;
    margin-top: 1.25rem;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: transparent;
    padding: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover, &:focus {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2371829e' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
}
  
.AttachDocumentSection-upload-area-icon {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    svg {
        max-height: 100%;
        max-width: 100%;
    }
}
  
.AttachDocumentSection-upload-area-title {
    margin-top: 1rem;
    display: block;
    font-weight: 700;
    color: #0d0f21;
}
  
.AttachDocumentSection-upload-area-description {
    display: block;
    color: #6a6b76;
    strong {
        color: #acb8cc;
        font-weight: 700;
    }
}

.AttachDocumentSection-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.AttachDocumentSection-upload-progress {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 1rem;
}

.AttachDocumentSection-progress-bar {
    height: 1rem;
    background-color: var(--primary-color);
    text-align: center;
    color: white;
    line-height: 1rem;
    border-radius: 4px;
    transition: width 0.4s ease;
}

.AttachDocumentSection-Asterisk {
    color: rgb(236, 106, 106);
}