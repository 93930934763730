#root-alert {
  height: 0;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.box-alert {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  color: #333;
  display: flex;
  font-size: 1.1em;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 250px;
  background-color: #fafafa;
  padding: 0.25rem 1rem;
  border-radius: 4px;
  max-width: 600px;

}

.box-alert:hover {
  cursor: pointer;
}

.box-alert-content {
  display: flex;
  align-items: center;
}

.box-alert-content div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.box-alert-content div h6, .box-alert-content div p {
  line-height: 1.2;
}
.box-alert-content div h6 {
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
}

.box-alert-content div p {
  font-size: 1rem;
  color: var(--color-page);
  margin-top: 0.5rem;
  line-height: 1.1;
}

.BoxAlertIcon {
  margin-right: 1rem;
  border-radius: 4px;
}

.BoxAlertIcon svg {
  padding: 0.5rem;
}

.alert-close {
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 25px;
  min-width: 25px;
  margin: 4px 4px 0 0;
  padding: 0;
}
.alert-close:hover {
  background-color: #fff;
}
.alert-close:focus {
  position: relative;
  top: 1px;
  outline: none;
}
