.NewRequestModal {
    width: 760px;
}

.NewRequestModal-Body {
    display: grid;
    column-gap: 1rem;
    height: 100%;
    row-gap: 1.5rem;
}

.NewRequestModal-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.NewRequestModal-Subtitle > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
    color: #bbb;
}

.NewRequestModal-Subtitle > b {
    color: #bbb;
}

.NewRequestModal-Subtitle > div > svg {
    width: 24px;
    fill: #bbb;
    height: 24px;
}

.NewRequestModal-Body-Parameters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    height: min-content;
}

.NewRequestModal-Body-Parameters > div {
    word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

.NewRequestModal-Body-Detail-Box {
    display: grid;
    grid-template-columns: 80px 1fr;
    width: 100%;
    align-items: center;
    column-gap: 0.75rem;
}

.NewRequestModal-Body-Detail-Box-Input {
    width: 120px;
}

.NewRequestModal-Body-Detail-Box-Justification {
    max-height: 120px;
    overflow-y: auto;
}

.NewRequestModal-Body-Detail-Box-Justification-TextEditor {
    color: var(--color-page);
    padding: 10px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 4px;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}

.NewRequestModal-Body-Detail-Box-Justification-TextEditor:focus {
    outline: none;
    border-color: var(--primary-color);
    border-width: 2px;
    padding: 10px 15px;
}

.NewRequestModal-Body-SearchPatient {
    height: 420px;
}

.NewRequestModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}