.DentistAppointmentPlan-Textarea {
    color: var(--color-page);
    padding: 12px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid #ccc;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}