.DentistAppointmentPreNatalModal {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    height: 72vh;
    min-height: 500px;
    min-width: 650px;
}

.DentistAppointmentPreNatalModal-Header {
    padding: 0rem 0rem 1rem;
    display: flex;
    column-gap: 1rem;
}

.DentistAppointmentPreNatalModal-Header > div {
    border-radius: 20px;
    height: 50px;
    padding: 16px;
    border: 1px solid rgb(241, 243, 245);
    cursor: pointer;
}

.DentistAppointmentPreNatalModal-Header > div:hover, .DentistAppointmentPreNatalModal-Header > div.active {
    background-color: var(--primary-color);
    color: white;
}

.DentistAppointmentPreNatalModal-Content {
    overflow-y: auto;
}

.DentistAppointmentPreNatalModal-RowBtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.DentistAppointment-Subtitle {
    font-weight: 900;
    font-size: 0.95rem;
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color);
}

.DentistAppointment-Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-bottom: 0.75rem;
}

.DentistAppointment-RealizedRow {
    margin-bottom: 0.5rem;
}

.DentistAppointment-List-Instance {
    padding: 1.5rem 1rem 2rem;

}

.DentistAppointment-List-ToothGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.DentistAppointment-List-TreatmentPlan {
    margin-top: 1rem;
}
