.AttendanceManager-TabManager {
	display: grid;
	grid-template-columns: 180px 180px 180px 180px 180px 180px;
    margin-bottom: 1rem;
}

.AttendanceManager-TabManager-btn {
	width: 100%;
	display: flex;
	font-size: 0.8rem;
	cursor: pointer;
	color: rgb(28, 30, 33);
	height: 40px;
	align-items: center;
	border-bottom: 1px solid #eee;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	color: #333333;
}

.AttendanceManager-TabManager-btn:hover {
	border-bottom: 1px solid var(--primary-color);
}

.AttendanceManager-TabManager-btn.active {
	color: var(--primary-color);
	fill: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}