.SignDocumentsModal {
    width: 320px;
    text-align: center;
}

.SignDocumentsModal-VerificationCode {
    margin-top: 0.5rem;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(6, 40.5px);
    column-gap: 0.5rem;
}

.SignDocumentsModal-Separator {
    height: 1px;
    background-color: #EEE;
    width: 100%;
    margin: 1.5rem 0rem;
}

.SignDocumentsModal-SignInputs p {
    margin: 1rem 0 2rem;
}

.SignDocumentsModal-BtnBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    justify-content: space-between;
    margin-top: 2rem;
}

.SignDocumentsModal-BtnBox button {
    width: 100%;
}