.NewEditVitalSign-Subtitle {
    color: rgb(36, 37, 46);
    font-weight: bold;
    font-size: 14px;
}

.NewEditVitalSign-MainRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
}

.NewEditVitalSign-MainRow-Input {
    width: 120px;
}

.NewEditVitalSign-Pressure {
    display: grid;
    grid-template-columns: 96px 96px;
    column-gap: 1rem;
}

.NewEditVitalSign-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}