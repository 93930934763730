.NewEditChronicConditions {
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.NewEditChronicConditions-BtnBox {
    display: flex;
    justify-content: flex-end;
}

.NewEditChronicConditions-Select .SelectBox, .NewEditChronicConditions-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.NewEditChronicConditions-Select div {
    width: 160px;
}

.NewEditChronicConditions-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}