.NewEditHospitalizations {
    width: 500px;
}

.NewEditHospitalizations-Body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    overflow-y: auto;
    height: 300px;
}

.NewEditHospitalizations-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}

.NewEditHospitalizations-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.NewEditHospitalizations-Input > div {
    width: 180px;
}

.NewEditHospitalizations-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}