.NewEditRoutine {
    width: 620px;
}

.NewEditRoutine-ActiveFilters {
    display: grid;
    column-gap: 0.5rem;
    row-gap: 0.25rem;
    grid-template-columns: 1fr;
}

.NewEditRoutine-Grid {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.NewEditRoutine-Grid-2FR {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
}

.NewEditRoutine-Grid-3FR {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
}

.NewEditRoutine-BtnBox {
    display: flex;
    justify-content: flex-end;
}

.NewEditRoutine-NewMultiSelect-FilterNode {
    padding: 0rem 0.25rem;
}