.BookingAppointment-Select {
    max-width: 300px;
}

.BookingAppointment-Select .SelectBox,
.BookingAppointment-Select .form-box,
.BookingAppointment-Input .form-box {
    padding-top: 8px;
}

.BookingAppointment-NewSelect-FilterBar {
    padding: 0.5rem;
}