.PlanUnit {
    padding-left: 0.5rem;
}

.PlanUnit-Subtitle {
    margin-bottom: 1rem;
    border: 1px solid #aaa;
    background-color: #fbfbfb;
    color: #aaa;
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}


.PlanUnit-Content {
    padding-left: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}
