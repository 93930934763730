.component-menu-item {
    padding: 8px 0 8px 10px;
    border-left: 3px solid transparent;
    cursor: pointer;
}

.component-menu-item.selected {
    border-left: 3px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
} 