.SelectScheduleHealthPlaceUserManager-Content {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.SelectScheduleHealthPlaceUserManager-FullWBtn button{
	width: 100%;
}

.SelectScheduleHealthPlaceUserManager-Separator {
	width: 1px;
	background-color: #eee;
}

.SelectScheduleHealthPlaceUserManager-Body {
	width: 100%;
}

.SelectScheduleHealthPlaceUserManager-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.SelectScheduleHealthPlaceUserManager-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.SelectScheduleHealthPlaceUserManager-UnitWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding-bottom: 1rem;
    align-items: center;
}

.SelectScheduleHealthPlaceUserManager-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	fill: #aaa;
	color: #aaa;
	height: calc(100vh - 240px);
}

.SelectScheduleHealthPlaceUserManager-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.SelectScheduleHealthPlaceUserManager-NotFound svg {
	width: 52px;
	height: 52px;
}

.SelectScheduleHealthPlaceUserManager-NotFound p {
	font-size: 1rem;
}
