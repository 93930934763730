
.Show-Patient-Data-Form-Container{
    border: 1px #ccc solid;
    padding: 30px 20px ;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
}

.Show-Patient-Data-Form-Title{
    position: absolute;
    top: -8px;
    left: 15px;
    background-color: white;
    font-weight: bold;
}

.Show-Patient-Data{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.Show-Patient-Data-Form-field{
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}