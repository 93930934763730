.ChronicConditionChart {
  width: 100%;
  width: 600px;
  margin-bottom: 2rem;
  position: relative;
  width: 500px;
  height: 300px;
  margin-bottom: 30px;
  cursor: pointer;
}

.ChronicConditionChart-Grid-Chart-Container {
  position: relative;
  width: 500px;
  height: 300px;
  margin-bottom: 30px;
  cursor: pointer;
}

.ChronicConditionChart-ChartOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(227, 240, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  opacity: 0;
  transition: opacity 0.3s;
  text-decoration: underline;
}

.ChronicConditionChart-Grid-Chart-Container:hover .ChronicConditionChart-ChartOverlay {
  opacity: 1;
}

.ChronicConditionChart-Modal {
  width: 100%;
  position: relative;
  width: 500px;
  cursor: pointer;
}