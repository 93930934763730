.PastTextAppointmentField {
    width: 600px;
    min-height: 300px;
}

.PastTextAppointmentField-Loading {
    width: 600px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PastTextAppointmentField-EmptyMessage-Box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
    min-height: 300px;
}

.PastTextAppointmentField-TextFieldBox {
    border-left: 4px solid var(--primary-color);
    background-color: #E3F0FF;
    border-radius: 4px;
    padding: 1rem 0.5rem;
}

.PastTextAppointmentField-TextFieldBox-InfoBox-TitleRow {
    color: #333333;
    font-weight: 800;
}

.PastTextAppointmentField-Body {
    max-height: 480px;
    overflow-y: auto;
    padding-right: 0.75rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.PastTextAppointmentField-Body::-webkit-scrollbar {
    width: 8px;
}

.PastTextAppointmentField-Body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.PastTextAppointmentField-Body::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 2px solid #f1f1f1; 
}

.PastTextAppointmentField-Body::-webkit-scrollbar-thumb:hover {
    background: #555;
}