.SOAP-AccordionNav {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),0 -1px rgba(0, 0, 0, 0.1) inset,0 2px 1px -1px rgba(255, 255, 255, 0.5) inset;
    width: 99.9%;
    background-color: #EEE;
    margin-bottom: 1rem;
}

.SOAP-AccordionNav-1000days, .SOAP-AccordionNav-Preceptorship {
    background-color: white;
    border: 1px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
}

.SOAP-AccordionNav-1000days-SubTitle, .SOAP-AccordionNav-Preceptorship-SubTitle {
    color: var(--primary-color);
}

.SOAP-Preceptorship-Section {
    border: 1px solid #EEE;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.SOAP-AccordionNav-1000days > div, .SOAP-AccordionNav-Preceptorship > div {
    display: flex;
    align-items: center;
}

.SOAP-AccordionNav-1000days-Hidden {
    display: none;
}

.SOAP-AccordionNav > svg {
    width: 12px;
    fill: black;
}

.SOAP-AccordionNav.open > svg {
    transform: rotate(180deg);
}

.SOAP-ConsultationSubtitle {
    color: rgb(36, 37, 46);
    font-weight: bold;
    font-size: 14px;
}

.SOAP-Section {
    transition: opacity 0.5s ease, height 0.5s ease;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding-bottom: 1rem;
    border: 1px solid #EEE;
    border-radius: 4px;
    padding: 2rem 1rem;
    margin-bottom: 1.5rem;
}

.SOAP-Section-1000Days-Frame {
    width: 100%; 
    height: 80vh; 
    border: none; 
    margin: 0; 
    padding: 0; 
    display: block;
}

.SOAP-Section-Separator {
    height: 1px;
    background-color: #EEE;
    width: 100%;
    margin: 1.5rem 0rem;
}

.SOAP-Section.open {
    opacity: 1;
    height: auto;
}
