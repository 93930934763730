.EditAddress {
    width: 900px;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.EditAddress-Asterisk {
    color: rgb(236, 106, 106);
}

.EditAddress-Select .SelectBox, .EditAddress-Select .form-box, .EditAddress-Input .form-box {
    padding-top: 4px;
}

.EditAddress-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 0.5rem;
    align-items: center;
}

.EditAddress-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: rgb(115, 127, 140);
    border-color: rgb(174, 182, 192);
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.EditAddress-Subtitle-Header-Icon-Blue {
    background-color: rgb(32, 45, 167);
    border-color: rgb(32, 45, 167);
}

.EditAddress-Subtitle-Header-Title {
    font-weight: 600;
}

.EditPatient-AddressInfo-4FR {
    display: grid;
    grid-template-columns: 180px 1fr 120px 300px;
    width: 100%;
    column-gap: 0.75rem;
}

.EditPatient-AddressInfo-2FR {
    display: grid;
    grid-template-columns: 1fr 152px;
    width: 100%;
    column-gap: 0.75rem;
}

.EditAddress-Btn-Box {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
}

.EditAddress-Btn-Box-SpanError {
    color: rgb(236, 106, 106);
}

.EditAddress-Btn-Box-Right-box {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}