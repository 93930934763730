.TextAppointmentField-ConsultationSubtitle {
    color: rgb(36, 37, 46);
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0.5rem;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.TextAppointmentField-ConsultationSubtitle svg {
    width: 20px;
    cursor: pointer;
    fill: #aaa;
    position: relative;
    top: 2px;
}

.TextAppointmentField-ConsultationSubtitle svg:hover {
    fill: var(--primary-bg);
}