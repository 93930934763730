.SOAPSubjective {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.SOAP-Subjective-Preceptorship-Label {
    color: #CED4DA;
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}