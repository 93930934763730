.AddressDataModalModal {
    width: 600px;
    min-height: 500px;
  }
  
  .AddressDataModalModal-BtnBox {
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }