.RegisterProfileFieldAgain {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 450px;
    background-color: var(--background-container);
}

.RegisterProfileFieldAgain section {
    max-width: 450px;
    width: 100%;
    border: rgb(219, 219, 219);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 4rem 2rem 2rem;
    position: relative;
    background-color: var(--background-container);
    border-radius: 4px;
}


.RegisterProfileFieldAgain-Headline {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
}

.RegisterProfileFieldAgain-Headline > h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.RegisterProfileFieldAgain-Title {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-bg);
}

.RegisterProfileFieldAgain-CancelRegister {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    color: var(--text-color);
    background-color: var(--background-container);
    border-radius: 4px;
}

.RegisterProfileFieldAgain-CancelRegister span b {
    color: var(--primary-bg);
}

.RegisterProfileFieldAgain-CancelRegister span b:hover {
    cursor: pointer;
    color: var(--primary-bgh);
    text-decoration: underline;
}

.RegisterProfileFieldAgain-Btn button {
    width: 100%;
}