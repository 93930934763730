.AssignScheduleToPatient-Grid {
    display: grid;
    grid-template-columns: 420px min-content 520px;
    column-gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
}

.AssignScheduleToPatient-Separator {
    width: 1px;
    background-color: #eee;
}

.AssignScheduleToPatient-2FRRow {
    display: flex;
    width: 100%;
    column-gap: 0.5rem;
}

.AssignScheduleToPatient-2FRRow > div {
    width: 100%;
}

.AssignScheduleToPatient-NewSelect {
    padding: 0rem 0.25rem;
}

.AssignScheduleToPatient-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}
