.LoginPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 450px;
  background-color: var(--background-container);
}

.LoginPage > section > h3 {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 3.5rem;
  color: var(--primary-bg);
}

.LoginPage section {
  max-width: 400px;
  width: 100%;
  border: rgb(219, 219, 219);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 4rem 2rem 2rem;
  position: relative;
  background-color: var(--background-container);
  border-radius: 4px;
}

.LoginPage-ForgetMyPassword {
  color: var(--primary-bg);
  text-align: right;
  cursor: pointer;
  position: relative;
  bottom: 0.4rem;
}

.LoginPage-ForgetMyPassword:active {
  color: var(--primary-bgh);
  text-decoration: underline;
}

.LoginPage-Btn-Mt {
  margin-top: 1rem;
}

.LoginPage-Btn div button {
  width: 100%;
}

.LoginPage-Register {
  margin-top: 1rem;
  max-width: 400px;
  width: 100%;
  border: rgb(219, 219, 219);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1.5rem;
  text-align: center;
  color: var(--text-color);
  background-color: var(--background-container);
  border-radius: 4px;
}

.LoginPage-Register span b {
  color: var(--primary-bg);
}

.LoginPage-Register span b:hover {
  cursor: pointer;
  color: var(--primary-bgh);
  text-decoration: underline;
}

@media (max-width: 576px) {
  .LoginPage section {
    max-width: 100%;
    border: unset;
    box-shadow: unset;
  }
  
  .LoginPage-Register {
    max-width: 100%;
    box-shadow: unset;
    margin-top: 0;
    padding: 0;
  }
}