.consultation-center {
    position: relative;
    padding: 0px;
}

.consultation-page .tabs-content {
    overflow: hidden;
    overflow-y: auto;
}

@media screen and (min-width: 460px) {

    .consultation-page .tabs-content {
        overflow: hidden;
        overflow-y: auto;
    }
}

@keyframes detach {
    0% {
        transform: rotateZ(-0.5deg);
    }

    25% {
        transform: rotateZ(0.5deg);
    }

    50% {
        transform: rotateZ(-0.5deg);
    }

    75% {
        transform: rotateZ(0.5deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

