
.datepicker .box-calendario {
  position: absolute;
  z-index: 3;
}

.box-calendario {
  background-color: #FFF;
  border-radius: 2rem;
  height: min-content;
  width: 300px;
}

.box-calendario-loading {
  height: 240px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-calendario-table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}

.box-calendario table {
  width: 100%;
}

.box-calendario thead td {
  text-align: center;
  font-weight: 500;
  font-size: 0.8rem;
}

.box-calendario tbody td.mes-passado {
  opacity: 0.3;
  visibility: hidden;
}

.box-calendario tbody td.proximo-mes {
  opacity: 0.3;
  display: none;
}

.box-calendario td.mes-atual {
  opacity: 1;
}
.box-calendario td.dia-atual {
  opacity: 1;
}

.box-calendario td.dia-atual > button {
  color: var(--primary-bg);
}

.box-calendario td.dia-atual .btn.normal.default {
  color: var(--primary-bg);
}

.box-calendario td.selected-day > button {
  background-color: var(--primary-color) !important;
  font-weight: bolder !important;
  color: white !important;
}

.box-calendario tbody td button {
  width: 100%;
}

.datepicker button.select-selected {
  padding: 8px 8px 8px 15px;
}

.datepicker button.select-selected:after {
  content: '';
  display: none;
}

.box-calendario tbody td button {
  position: relative;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  font-weight: 500;
}

.box-calendario tbody td button:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 4px;
  position: absolute;
  bottom: 4px;
}

.box-calendario tbody td button.disponivel:after {
  background-color: var(--disponivel-bg);
}
.box-calendario tbody td button.ocupado:after {
  background-color: var(--ocupado-bg);
}
.box-calendario tbody td button.fechado:after {
  background-color: var(--warning-bg);
}
.box-calendario tbody td button.compromisso:after {
  background-color: var(--compromisso-bg);
}

.box-calendario td.dia-anterior {
  opacity: 0.3;
}

.Calendar-YearGrid {
  max-height: 280px;
  overflow-y: auto;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.Calendar-YearGrid div {
  border-radius: 24px;
  padding: 0.5rem;
}

.Calendar-YearGrid div:hover {
  background-color: #eee;
  cursor: pointer;
}

.Calendar-YearGrid div.active {
  background-color: var(--primary-color);
  color: white;
}

.Calendar-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
}

.Calendar-Header-Btn {
  display: flex;
  column-gap: 0.5rem;

}

.Calendar-Header-Btn:hover {
  cursor: pointer;
}

.Calendar-Header-Btn svg {
  transform: rotate(-90deg);
  position: relative;
  bottom: 3.5px;
}

.Calendar-Header-Btn.active svg, .Calendar-Header-Btn:hover svg {
  transform: rotate(-270deg);
  top: 3.5px;
  bottom: 0px;
  transition: 0.2s;
}

.Calendar-Header-BtnBox {
  display: flex;
  column-gap: 0.5rem;
}

.Calendar-Header-BtnBox svg:hover {
  fill: var(--primary-color);
  cursor: pointer;
}