
.ImediatePostPartum-2fr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ImediatePostPartum-Subtitle {
    font-weight: 900;
    font-size: 0.95rem;
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color);
}