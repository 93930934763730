.HealthPlaceUserConfig-Title {
    font-weight: bold;
}

.HealthPlaceUserConfig-InputRatio {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 100px 100px;
    column-gap: 1rem;
}

.HealthPlaceUserConfig-BtnBox {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 6rem 6rem;
    column-gap: 4rem;
}