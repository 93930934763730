.NewEditCouncilModal{
    width: 400px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
  }
  
.NewEditCouncilModal-Subtitle {
  margin: 20px 0;
}

.NewEditCouncilModal-Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
}
    
.NewEditCouncilModal-SaveBtn {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.NewEditCouncilModal-Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
}

.NewEditCouncilModal-NewSelect {
  padding: 0rem 0.25rem;
}