.SelectPatient {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.SelectPatient-DropdownBox {
    background-color: var(--background-container);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 4px;
    overflow: auto;
    position: absolute;
    margin-top: 0.5rem;
    width: 100%;
    z-index: 3;
}

.SelectPatient-DropdownBox-SelectOptions {
    position: relative;
    overflow-y: auto;
    max-height: 280px;
}

.SelectPatient-DropdownBox-SelectOptions > div {
    color: var(--input-color);
    cursor: pointer;
    padding: 0.75rem;
    position: relative;
    font-size: 0.9rem;
    border-top: 0.05px solid #eee;
}

.SelectPatient-DropdownBox-SelectOptions > div:hover {
    background-color: rgb(238, 238, 238, 0.5);
    padding-left: 1rem;
}

.SelectPatient-DropdownBox-SelectOptions > div.selected {
    background-color: rgb(238, 238, 238, 0.5);
    font-weight: 600;
}

.SelectPatient-DropdownBox-SelectOptions > div:active {
    position: relative;
}

.SelectPatient-DropdownBox-SelectOptions > div {
    align-items: center;
    display: flex;
}

.SelectPatient-SelectedPatient-Wrapper {
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 8px;
}

.SelectPatient-SaluthoSpan {
    color: var(--primary-color);
    font-size: 1rem;
    text-align: end;
}

.SelectPatient-2Col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
}

.SelectPatient-Btn-FullWidth button {
    width: 100%;
    margin-top: 0.5rem;
}

.SelectPatient-BtnBox {
    display: flex;
    margin-top: auto;
    column-gap: 0.5rem;
    justify-content: flex-end;
}

.SelectPatient-SelectRow {
    padding: 0.5rem;
}

.SelectPatient-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	fill: #aaa;
	color: #aaa;
}

.SelectPatient-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.SelectPatient-NotFound svg {
	width: 32px;
	height: 32px;
}

.SelectPatient-NotFound p {
	font-size: 0.9rem;
}