.Merge-Patient-Modal{
    min-width: 50vw;
    max-width: 65vw;
    min-height: 40vh;
    max-height: 60vh;

}

.Merge-Patient-info-container{
    display: flex;
    gap: 25px;
}

.Merge-Patient-Button-Container{
    display: flex;
    gap: 5px;
    justify-content: end;
    align-items: end;
}