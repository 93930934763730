.PatientCallPanel {
    display: grid;
    /*grid-template-rows: 3fr 5fr 80px;*/
    grid-template-rows: 1fr 80px;
    width: 100vw;
    height: 100vh;
}

.PatientCallPanel-Header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid var(--primary-color);
    width: 100%;
    border: 1px solid var(--primary-color);
}

.PatientCallPanel-Header-CurrentCall {
    background-color: var(--primary-color);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 10vh;
}

.PatientCallPanel-Header-ServiceLocal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 10vh;
}

.PatientCallPanel-Header-ServiceLocal-CalledTitle {
    color: var(--primary-color);
}

.PatientCallPanel-Header-ServiceLocal-Called {
    color: #aaa;
}

.PatientCallPanel-Content-Header {
    display: flex;
    padding: 1rem 0rem 2rem;
    align-items: center;
    justify-content: center;
    font-size: 5vh;
    font-weight: 200;
    color: #aaa;
}

.PatientCallPanel-Content-List-Header {
    text-align: center;
    display: grid;
    grid-template-columns: 65vw 20vw 15vw;
    font-size: 4vh;
    line-height: 0;
    color: var(--primary-color);
}

.PatientCallPanel-Content-List {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.PatientCallPanel-Content-List-Row {
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 65vw 20vw 15vw;
    font-size: 5vh;
    font-weight: 500;
    color: white;
    background-color: var(--primary-color);
    padding: 1rem 0 0.5rem;
}

.PatientCallPanel-Footer {
    display: grid;
    grid-template-columns: 400px 1fr;
}

.PatientCallPanel-Footer-CurrentHour {
    background-color: var(--primary-color-darker);
    color: white;
    font-size: 52px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PatientCallPanel-Footer-Branding {
    background-color: var(--primary-color);
    color: white;
    font-size: 52px;
    font-weight: 500;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 1rem;
}


.PatientCallPanel-Footer-Branding-Logo {
    height: 80px;
    object-fit: contain;
    padding: 0.25rem 0;
  }
  