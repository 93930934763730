.UnavailableScheduleModal {
    width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.UnavailableScheduleModal-Title {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.UnavailableScheduleModal-Title > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
    color: #bbb;
}

.UnavailableScheduleModal-Title > b {
    color: #bbb;
}

.UnavailableScheduleModal-Title > div > svg {
    width: 24px;
    fill: #bbb;
    height: 24px;
}

.UnavailableScheduleModal-TimeRow {
    display: flex;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-wrap: wrap;
}

.UnavailableScheduleModal-TimeRow > div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.25rem;
}

.UnavailableScheduleModal-Message {
    font-style: italic;
}