.TreponemalTestWrite-title {
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.TreponemalTestWrite-Content {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.TreponemalTestWrite-Data {
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 1rem;
}

.TreponemalTestWrite-NonTreponemalResult {
    margin-bottom: 1rem;
}

.TreponemalTestWrite-NotifyHealthSurveillance {
    margin-top: 1rem;
}

.TreponemalTestWrite-NotifyHealthSurveillance-Input {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.TreponemalTestWrite-Notify-Health-Surveillance-Button {
    margin-bottom: 2rem;
}