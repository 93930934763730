.HealthPlaceForm {
  display: grid;
  grid-template-columns: 160px min-content 1fr;
  column-gap: 1.5rem;
  flex-grow: 1;
  height: 600px;
  width: fit-content;
}

.HealthPlaceForm-Body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.HealthPlaceGeneralData, .HealthPlaceAddressData, .HealthPlaceLogoData {
  width: 480px;
}

.HealthPlaceTimesData {
  width: 1020px;
}

.HealthPlaceForm-separator {
  width: 1px;
  min-height: 100%;
  background-color: #eee;
}

.HealthPlaceForm-SideLeft-Btn {
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(28, 30, 33);
  height: 52px;
  align-items: center;
  padding-left: 1rem;
  border-left: 3px solid white;
  font-weight: 400;
}

.HealthPlaceForm-SideLeft-Btn:hover {
  background-color: #eee;
  border-left: 3px solid #eee;
}

.HealthPlaceForm-SideLeft-Btn.active {
  color: var(--primary-color);
  fill: var(--primary-color);
  font-weight: 700;
  border-left: 3px solid var(--primary-color);
}

.HealthPlaceForm-Btn-Box {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
}