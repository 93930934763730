.tooltip-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip, .tooltip-arrow {
    position: absolute;
    display: none;
}

.tooltip-wrapper:hover .tooltip{
/* .tooltip{ */
    display: block;
    bottom: 40px;
    padding: 4px 8px 5px 8px;
    background-color: rgba(0,0,0,.8);
    font-size: 12px;
    color: white;
    border-radius: 4px;
    bottom: calc(100% + 10px);
}

.tooltip-wrapper:hover .tooltip-arrow{
    display: block;
    bottom: 31px;
    width: 8px;
    height: 8px;
    transform: rotateZ(45deg);
    background-color: rgba(0,0,0,.8);
}

