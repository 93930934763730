.EditPatient {
    width: 1000px;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.NewPatinet-Asterisk {
    color: rgb(236, 106, 106);
}

.EditPatient-Select .SelectBox, .EditPatient-Input .form-box {
    padding-top: 4px;
}

.EditPatient-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 0.5rem;
    align-items: center;
}

.EditPatient-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: rgb(115, 127, 140);
    border-color: rgb(174, 182, 192);
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.EditPatient-Subtitle-Header-Icon-Blue {
    background-color: rgb(0, 116, 182);
    border-color: rgb(0, 116, 182);
}

.EditPatient-Subtitle-Header-Title {
    font-weight: 600;
}

.EditPatient-PersonalInfo {
    display: flex;
    flex-direction: column;
}

.EditPatient-PersonalInfo-NameBirthdayCPFContainer {
    display: grid;
    grid-template-columns: 3fr 150px min-content 132px 142px;
    width: 100%;
    column-gap: 0.75rem;
}

.EditPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template-columns: 80px 112px 80px;
}

.EditPatient-PersonalInfo-CNSMotherNameEmailContact {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 200px 3fr 132px 142px;
}

.EditPatient-PersonalInfo-SocialNameCNSMotherNameEmailContact {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 3fr 200px 3fr 132px 142px;
}

.EditPatient-PersonalInfo-NationalitySexGenderMaritalStatusGender {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 132px 132px 150px 150px 150px 1fr;
}

.EditPatient-PersonalInfo-NationalityInfo-FirstRow {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: min-content 1fr 1fr;
}

.EditPatient-PersonalInfo-NationalityInfo-SecondRow {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 1fr min-content min-content;
}

.EditPatient-PersonalInfo-2FR {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
}

.EditPatient-FamilyInfo {
    display: flex;
    flex-direction: column;
}

.EditPatient-FamilyInfo-RowInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 160px 172px 172px;
    column-gap: 0.75rem;
}

.EditPatient-Btn-Box {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
}

.EditPatient-Btn-Box-SpanError {
    color: rgb(236, 106, 106);
}

.EditPatient-BtnBox-RightBox {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}