.StreptococcusGBSView > div {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
}

.StreptococcusGBSView > div > .list > .list-headers {
    background-color: #eee;
}

.StreptococcusGBSView > div > .list > .list-headers > .header {
    color: #333333;
}