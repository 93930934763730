.InsertInventoryMaterialsModal {
    display: flex;
    flex-direction: column;
    width: 800px;
}

.InsertInventoryMaterialsModal-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.InsertInventoryMaterialsModal-Subtitle > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
    color: #bbb;
}

.InsertInventoryMaterialsModal-Subtitle > b {
    color: #bbb;
}

.InsertInventoryMaterialsModal-Subtitle > div > svg {
    width: 24px;
    fill: #bbb;
    height: 24px;
}

.InsertInventoryMaterialsModal-NewSelect {
    padding: 0.5rem;
}

.InsertInventoryMaterialsModal-Select .SelectBox, .InsertInventoryMaterialsModal-Select .form-box, .InsertInventoryMaterialsModal-Input .form-box {
    padding-top: 4px;
}

.InsertInventoryMaterialsModal-Asterisk {
    color: rgb(236, 106, 106);
}

.InsertInventoryMaterialsModal-Form-Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.InsertInventoryMaterialsModal-Form-Inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.InsertInventoryMaterialsModal-Input-2Col {
    grid-column: span 2;
}

/* .InsertInventoryMaterialsModal-Form-Body-1W {
    width: 240px;
}

.InsertInventoryMaterialsModal-Form-Body-2W {
    width: 496px;
} */

.InsertInventoryMaterialsModal-Form-Body-ExpirationDate {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template-columns: 94px 112px 94px;
}

.InsertInventoryMaterialsModal-Form-ImgBox {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InsertInventoryMaterialsModal-Form-ActionRow {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 0.5rem;
}

.InsertInventoryMaterialsModal-Form-ActionRow-DeleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.InsertInventoryMaterialsModal-Form-ActionRow-BtnBox {
    display: flex;
    column-gap: 1rem;
}