.FastingBloodGlucoseWrite-DataRowDefault {
	display: grid;
	grid-template-columns: 150px 1fr;
	column-gap: 1rem;
}

.FastingBloodGlucoseWrite-DataSelectRow {
	display: grid;
	grid-template-columns: 216px 1fr;
	column-gap: 1rem;
}

.FastingBloodGlucoseWrite-title {
	font-weight: bold;
	margin-bottom: 1.5rem;
}
