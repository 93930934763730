.PartnerPrenatal-Subtitle {
    font-weight: 900;
    font-size: 0.95rem;
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color);
}

.ThousandDays_Consultation_PartnerPrenatal_Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.ThousandDays_Consultation_PartnerPrenatal_Action {
    overflow-x: hidden;
    overflow-y: auto;

}

.ThousandDays_Consultation_PartnerPrenatal_AltText {
    margin: 0;
    line-height: 10px;
    font-size: 11px;
}

.ThousandDays_Consultation_PartnerPrenatal_ConfirmData {
    display: flex;
}

.ThousandDaysPrenatalPartner_UnknownCheckbox {
    margin-bottom: 1rem;
}

.ThousandDaysPrenatalPartner_Name {
    width: 360px;
}

.ThousandDaysSection-PrenatalPartner-SelectRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ThousandDaysPrenatalPartner_Birthdate {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 5rem 7.5rem 5rem;
}

.ThousandDaysPrenatalPartner_Instruction {
    width: 260px;
}

.ThousandDaysPrenatalPartner_SelectDefaultCSS {
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 1rem;
}

.ThousandDaysPrenatalPartner-PartnerPrenatal-ExamsSubsection-RadioBtn {
    margin-top: 0.5rem;
}

.ThousandDaysSection-PartnerPrenatal-ExamsSubsection-Selectors {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ThousandDaysPrenatalPartner_FamilyBackgroundInputBox {
    display: grid;
    grid-template-columns: max-content min-content;
    column-gap: 1rem;
    width: 300px;
}

.ThousandDaysPrenatalPartner_FamilyBackgroundList {
    width: 400px;
}

.ThousandDaysPrenatalPartner_FamilyBackgroundList_Btn {
    padding-top: 12px;
}

.ThousandDaysSection-Consultation-PartnerPrenatal-Buttons {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .ThousandDays_Consultation_PartnerPrenatal {
        grid-template-rows: unset;
    }
}
@media (max-width: 576px) {
    .ThousandDaysPrenatalPartner_IMC {
        grid-template-columns: 100px 100px;
        grid-template-areas: 
        "weight height"
        "imc imc";
    }
}