.AppointmentLogsManager-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.AppointmentLogsManager-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 148px);
}

.AppointmentLogsManager-Content {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.AppointmentLogsManager-Separator {
	width: 1px;
	min-height: 45vh;
	background-color: #eee;
}

.AppointmentLogsManager-Body {
	width: 100%;
}

.AppointmentLogsManager-AttendanceUnitWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding-bottom: 1rem;
}

.AppointmentLogsManager-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	fill: #aaa;
	color: #aaa;
	height: calc(100vh - 240px);
}

.AppointmentLogsManager-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.AppointmentLogsManager-NotFound svg {
	width: 52px;
	height: 52px;
}

.AppointmentLogsManager-NotFound p {
	font-size: 1rem;
}

.AppointmentLogsManager-DetailLog-Span {
	text-decoration: underline;
	color: var(--primary-color-lighter);
	cursor: pointer;
}

.AppointmentLogsManager-DetailLog-Span:hover {
	color: var(--primary-color);
}