.DetailOphthalmologyInfo-Row {
    display: flex;
    column-gap: 0.5rem;
    margin-top: 1.5rem;
}

.DetailOphthalmologyInfo-Notes {
    height: 80px;
    overflow-y: auto;
}

.glasses-space { grid-area: glasses-space; }
.glasses-degreeE { grid-area: glasses-degreeE; }
.glasses-degreeC { grid-area: glasses-degreeC; }
.glasses-axis { grid-area: glasses-axis; }
.glasses-DNP { grid-area: glasses-DNP;}
.glasses-rEyeFlag { grid-area: glasses-rEyeFlag;}
.glasses-lEyeFlag { grid-area: glasses-lEyeFlag;}
.glasses-rEDegreeInput { grid-area: glasses-rEDegreeInput;}
.glasses-lEDegreeInput { grid-area: glasses-lEDegreeInput;}
.glasses-rCDegreeInput { grid-area: glasses-rCDegreeInput;}
.glasses-lCDegreeInput { grid-area: glasses-lCDegreeInput;}
.glasses-rAxisInput { grid-area: glasses-rAxisInput;}
.glasses-lAxisInput { grid-area: glasses-lAxisInput;}
.glasses-rDNPInput { grid-area: glasses-rDNPInput;}
.glasses-lDNPInput { grid-area: glasses-lDNPInput;}

.glasses-space, .glasses-degreeE, .glasses-degreeC, .glasses-axis, .glasses-DNP {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.glasses-space b {
    visibility: hidden;
}

.glasses-rEyeFlag p, .glasses-lEyeFlag p {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: end;
}

.glasses-addition {
    width: 90px
}

.glasses-degreeE b, .glasses-degreeC b, .glasses-axis b, .glasses-DNP b {
    position: relative;
    margin-bottom: 16px;
    font-weight: bold;
}

.glasses-container, .glasses-container-2 {
    display: grid;
    text-align: center;
    grid-template-areas:
    'glasses-space glasses-rEyeFlag glasses-lEyeFlag'
    'glasses-degreeE glasses-rEDegreeInput glasses-lEDegreeInput'
    'glasses-degreeC glasses-rCDegreeInput glasses-lCDegreeInput'
    'glasses-axis glasses-rAxisInput glasses-lAxisInput'
    'glasses-DNP glasses-rDNPInput glasses-lDNPInput';
    column-gap: 4px;
    grid-template-columns: 75px 90px 90px;
}

.glasses-alignRefractionTitles > .glasses-degreeE, 
.glasses-alignRefractionTitles > .glasses-degreeC, 
.glasses-alignRefractionTitles > .glasses-axis, 
.glasses-alignRefractionTitles > .glasses-DNP {
    margin-bottom: 8px;
}

.glasses-notes {
    height: 170px;
    overflow-y: auto;
}

@media (min-width: 500px) {
    .glasses-container {
        display: grid;
        grid-template-areas:
        'glasses-space glasses-degreeE glasses-degreeC glasses-axis glasses-DNP'
        'glasses-rEyeFlag glasses-rEDegreeInput glasses-rCDegreeInput glasses-rAxisInput glasses-rDNPInput'
        'glasses-lEyeFlag glasses-lEDegreeInput glasses-lCDegreeInput glasses-lAxisInput glasses-lDNPInput';
        grid-template-columns: 40px repeat(3, 90px);
    }

    .glasses-container-2 {
        display: grid;
        grid-template-areas:
        'glasses-space glasses-degreeE glasses-degreeC glasses-axis glasses-DNP'
        'glasses-rEyeFlag glasses-rEDegreeInput glasses-rCDegreeInput glasses-rAxisInput glasses-rDNPInput'
        'glasses-lEyeFlag glasses-lEDegreeInput glasses-lCDegreeInput glasses-lAxisInput glasses-lDNPInput';
        grid-template-columns: 40px repeat(4, 90px);
    }

    .glasses-degreeE b, .glasses-degreeC b, .glasses-axis b, .glasses-DNP b {
        margin-bottom: 0px;
    }

    .glasses-rEyeFlag, .glasses-lEyeFlag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .glasses-rEyeFlag p, .glasses-lEyeFlag p {
        display: unset;
        margin-bottom: 20px;
    }
}