.TicketsManager-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.TicketsManager-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.TicketsManager-List-Filters-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    column-gap: 0.5rem;
}

.TicketsManager-List-Filters-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.TicketsManager-List-Filters-ActionRow-Recall > svg {
	height: 16px !important;
	font-size: 16px !important;
	width: 16px !important;
	margin: 0;
}

.TicketsManager-List-Filters-ActionRow-Recall > svg:hover {
	fill: var(--primary-color);
}

.TicketsManager-List-Filters-ActionRow-Error > svg:hover {
    fill: #F95C5C;
}

.TicketsManager-List-Filters-ActionRow-Success > svg:hover {
    fill: #14A44D;
}

.TicketsManager-List-Filters-HeaderRow-Action {
    text-align: right;
}

.TicketsManager-List-HeaderContent-OutlinedEffect {
    border: 1px solid var(--primary-color);
    background-color: #E3F0FF;
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    text-align: center;
}

.TicketsManager-Content {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.TicketsManager-Separator {
	width: 1px;
	min-height: 80vh;
	background-color: #eee;
}

.TicketsManager-Body {
	width: 100%;
}

.TicketsManager-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	fill: #aaa;
	color: #aaa;
	height: calc(100vh - 240px);
}

.TicketsManager-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.TicketsManager-NotFound svg {
	width: 52px;
	height: 52px;
}

.TicketsManager-NotFound p {
	font-size: 1rem;
}
