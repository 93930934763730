.FilterInventoryParams-ActiveFilters {
	display: flex;
	flex-direction: column;
}

.FilterInventoryParams-SideBarLeft-ActionRow {
	margin: 0.5rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
    margin-bottom: 1.5rem;
}

.FilterInventoryParams-SideBarLeft-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
}

.FilterInventoryParams-SideBarLeft-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.FilterInventoryParams-SideBarLeft-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.FilterInventoryParams-SideBarLeft-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.FilterInventoryParams-Title {
	font-weight: bold;
	font-size: 1em;
}

.FilterInventoryParams-List {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: 10px;
	padding: 0;
}

.FilterInventoryParams-Button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
	border-radius: 14px;
}

.FilterInventoryParams-Separator {
	width: 1px;
	height: 20px;
	background-color: var(--input-color);
}

.FilterInventoryParams-Name {
	margin: 0;
	color: var(--input-color);
	font-size: 1.2em;
	padding: 10px;
}

.FilterInventoryParams-Remove {
	color: var(--input-color);
	text-transform: uppercase;
	font-size: 1.3em;
	padding: 10px;
	width: 60px;
	max-width: max-content;
}

.FilterInventoryParams-Button:hover {
	background-color: var(--primary-color-light-2);
} 

.FilterInventoryParams-Button:hover .FilterInventoryParams-Separator {
	width: 0;
} 

.FilterInventoryParams-Button:hover .FilterInventoryParams-Name {
	color: var(--primary-color-dark);
}

.FilterInventoryParams-Button:hover .FilterInventoryParams-Remove {
	background-color: var(--primary-color);
	color: white;
}