.RenderAppointments {
    position: absolute;
    width: 100%; 
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0.2rem;
    opacity: 1;
    display: flex;
    align-items: center;
}

.RenderAppointments-Field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap;
    column-gap: 1rem;
    font-size: 14px;
    width: 100%;
}

.RenderAppointments-Field-HourTime {
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
}