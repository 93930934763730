.NewEditLifeHabitsAndBehavior {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 600px;
}

.NewEditLifeHabitsAndBehavior-BtnBox {
    display: flex;
    justify-content: flex-end;
}

.NewEditLifeHabitsAndBehavior-Select .SelectBox, .NewEditLifeHabitsAndBehavior-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    width: 200px;
}

.NewEditLifeHabitsAndBehavior-Select div {
    width: 200px;
}

.NewEditLifeHabitsAndBehavior-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}