.InventoryMaterialImageModal {
    width: 900px;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.InventoryMaterialImageModal-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 0.5rem;
    align-items: center;
}

.InventoryMaterialImageModal-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: rgb(115, 127, 140);
    border-color: rgb(174, 182, 192);
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.InventoryMaterialImageModal-Subtitle-Header-Icon-Purple {
    background-color: #0B2027;
    border-color: #0B2027;
}

.InventoryMaterialImageModal-Subtitle-Header-Title {
    font-weight: 600;
}

.InventoryMaterialImageModal-Container-Box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.InventoryMaterialImageModal-img-preview {
    overflow: hidden;
}
  
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}
  
.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
  
.InventoryMaterialImageModal-ActionRow{
    display: flex;
    gap: 5px;
    align-items: center;
}

.InventoryMaterialImageModal-Action{
    cursor: pointer;
    background-color: white;
    border: none;
}


.InventoryMaterialImageModal-Btn-Box {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}