.SerologicalTestRubToxoCitoWrite-title {
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.SerologicalTestRubToxoCitoWrite-mt {
    margin-top: 1rem;
}

.SerologicalTestRubToxoCitoWrite-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin-bottom: 1.5rem;
}