.MarkTimeAsAvailable {
    width: 600px;
    height: 400px;
}

.MarkTimeAsAvailable-TimeRow {
    display: flex;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    height: 300px;
    overflow-y: auto;
}

/* ScrollBar Style */
.MarkTimeAsAvailable-TimeRow::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.MarkTimeAsAvailable-TimeRow::-webkit-scrollbar-track {
    background: transparent;
}

.MarkTimeAsAvailable-TimeRow::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.MarkTimeAsAvailable-TimeRow::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.MarkTimeAsAvailable-TimeRow > div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.25rem;
}

.MarkTimeAsAvailable-Box {
    display: grid;
    grid-template-columns: 80px 1fr;
    width: 100%;
    align-items: center;
    column-gap: 0.75rem;
}

.MarkTimeAsAvailable-Box-Justification {
    max-height: 120px;
    overflow-y: auto;
}

.MarkTimeAsAvailable-Box-Justification-TextEditor {
    color: var(--color-page);
    padding: 10px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 4px;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}

.MarkTimeAsAvailable-Box-Justification-TextEditor:focus {
    outline: none;
    border-color: var(--primary-color);
    border-width: 2px;
    padding: 10px 15px;
}

.MarkTimeAsAvailable-BtnBox {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}