.Consultation-ThousandDays-GestationSubSection-Header {
    display: grid;
    grid-template-columns: 180px min-content min-content;
    column-gap: 0.5rem;
    text-align: center;
    align-items: center;
    margin: 1rem 0rem;
}

.GestationSubSection-SubsectionWrapper {
    margin-top: 1rem;
}