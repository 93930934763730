.RecurringPatientModal {
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 640px;
}

.RecurringPatientModal-PageThree {
    display: flex;
    flex-direction: column;
    height: max-content;
}

.RecurringPatientModal-BtnBox {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.RecurringPatientModal-Asterisk {
    color: rgb(236, 106, 106);
}

.RecurringPatientModal-Error {
    margin-top: 1rem;
    color: rgb(236, 106, 106);
    font-size: 11px;
    line-height: 0;
}

.RecurringPatientModal-Input {
    cursor: pointer;
}

.RecurringPatientModal-Select .SelectBox, .RecurringPatientModal-Input .form-box {
    padding-top: 4px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.RecurringPatientModal-HourCalculator-InputGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
}
.RecurringPatientModal-DaysOfTheWeek {
    display: flex;
    column-gap: 0.25rem;
    margin-top: 0.75rem;
}

.RecurringPatientModal-RepeatFrequency {
    margin-bottom: 1rem;
}

.RecurringPatientModal-InputRow {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.RecurringPatientModal-InputRow .form-box {
    padding: 0rem;
    margin: 0rem;
    width: 72px;
}

.RecurringPatientModal-DaysOfTheWeek > div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    cursor: pointer;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    height: 24px;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin-right: 8px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    width: 24px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
}

.RecurringPatientModal-DaysOfTheWeek > div:hover {
    opacity: 0.7;
}

.RecurringPatientModal-DaysOfTheWeek > div.active {
    background-color: var(--primary-color);
    color: white;
}

.RecurringPatientModal-DateGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.RecurringPatientModal-DateSelect {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template-columns: 80px 112px 80px;
}

.RecurringPatientModal-ProgressBar {
    height: 8px;
    background-color: #e0e0e0;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
}

.RecurringPatientModal-ProgressBar-Fill {
    height: 100%;
    background-color: #3b82f6;
    border-radius: 5px;
    transition: width 0.3s ease;
}