.CreateGenericCouncil-Subtitle {
  color: var(--text-color);
  font-weight: 700;
}


.CreateGenericCouncil-mt {
  margin-top: 1.5rem;
}

.CreateGenericCouncil-CROCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
}

.CreateGenericCouncil-BtnFullWidth button {
  width: 100%;
}

.CreateGenericCouncil-SaveCouncil {
  margin-top: 1rem;
}

.CreateGenericCouncil-SaveCouncil button {
  width: 100%;
}

.CreateGenericCouncil-ActionRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  column-gap: 0.75rem;
}

.CreateGenericCouncil-ActionRow > div > svg {
  width: 20px;
  fill: #bbb;
  cursor: pointer;
  height: 20px;
}

.CreateGenericCouncil-ActionRow > div > svg:hover {
  fill: var(--primary-color);
}

.CreateGenericCouncil-NewSelect {
  padding: 0rem 0.25rem;
}