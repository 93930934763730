.Partograph-ActiveLabour-GraphWrapper {
    display: flex;
    justify-content: space-between;
}

.Partograph-ActiveLabour-2FR {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.Partograph-ActiveLabour-Input {
    width: 140px;
}

.Partograph-ActiveLabor-Position-Absolute {
    position: absolute;
    margin-left: 15px !important;
    margin-top: 32px;
}

.Partograph-ActiveLabour-Table {
    width: 100%;
    border: 1px solid var(--primary-color);
}

.Partograph-ActiveLabour-Table th {
    border: 1px solid #D9E4E6;
    background-color: var(--primary-color);
    padding: 0.5rem;
    color: white;
}

.Partograph-ActiveLabour-Table td {
    padding: 0.75rem 0rem;
    border: 0.001px solid #eef7ff;
}

.Partograph-ActiveLabour-Table tr td:first-child {
    padding-left: 0.75rem;
}

.Partograph-ActiveLabour-Table-OddRow > td {
    background-color: #eef7ff;
}

.Partograph-ActiveLabour-Table-Radio {
    border: 1px solid #bbb;
    border-radius: 50%;
    height: 20px;
    transition: all 0.3s;
    width: 20px;
    margin: auto;
    cursor: pointer;
}

.Partograph-ActiveLabour-Table-Radio.filled {
    background-color: var(--primary-color);
}

.Partograph-ActiveLabour-Table-Input {
    border: none;
    width: 36px;
    margin: auto;
    display: flex;
    text-align: center;
    font-size: 14px;
    background-color: transparent;
}

.Partograph-ActiveLabour-Table-Grid line {
    stroke: var(--primary-color);
}