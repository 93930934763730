.RemoveHealthPlaceModal-ErrorDisclaimer {
    background-color: var(--error-bg);
    border: 1px solid var(--error-bgh);
    color: var(--error-color);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    margin-bottom: 1rem;
}

.RemoveHealthPlaceModal-Input {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.RemoveHealthPlaceModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}