.DynamicRefraction-Header {
    display: grid;
    grid-template-columns: 32px 100px 100px 100px 100px;
    column-gap: 0.5rem;
    margin-bottom: 0.25rem;
    text-align: center;
    align-items: center;
}

.DynamicRefraction-Grid {
    display: grid;
    grid-template-columns: 32px 100px 100px 100px 100px;
    column-gap: 0.5rem;
    text-align: center;
    align-items: center;
    row-gap: 1.25rem;
}

.DynamicRefraction-Grid .SelectBox, .DynamicRefraction-Grid .form-box {
    margin: 0;
    padding: 0;
}