.NewEditPreceptorshipRoomModal {
    width: 500px;
    max-height: 85vh;
    overflow-y: auto;
}

.NewEditPreceptorshipRoomModal-BtnBox {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.NewEditPreceptorshipRoomModal-Textarea {
    color: var(--color-page);
    padding: 12px 15px;
    word-wrap: break-word;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    border: 1px solid #ccc;
    resize: none;
    min-height: fit-content !important;
    overflow-y: hidden;
}