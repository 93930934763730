.AppointmentUnit-Container{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    /*border: 1px solid #eee;*/
    padding: 1rem;
    border-radius: 8px;
    gap: 10px;
}

.AppointmentUnit-InfoLine {
    display: flex;
    gap: 5px;
    align-items: center;
}

.AppointmentUnit-InfoLine > p{
    margin-bottom: 0;
}

.AppointmentUnit-Title{
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 5px;
}

.AppointmentUnit-Status{
    font-size: 1rem;
    font-weight: bold;
}

.status-line {
    display: flex;
    justify-content: center;
    padding: 20px 0
}