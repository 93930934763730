.SelectBox {
    position: relative;
    margin-bottom: 15px;
    padding-top: 10px;
    min-height: 14px;
}

.SelectBox label {
    color: var(--input-color);
    display: block;
    font-size: 1em;
    background-color: var(--input-bg);
    box-shadow: 0px 0px 5px var(--input-bg);
    color: var(--text-color);
    font-weight: bold;
    display: inline-block;
    padding: 0px 5px;
    position: absolute;
    top: 2px;
    left: 13px;
    /* z-index: 2; */
}

.Select-Btn-Error {
    border: 2px solid var(--error-bg) !important;
    padding: 11px 15px;
}

.Select-Text-Error {
    color: var(--error-bg);
    padding: 0 15px;
    font-size: 11px;
    display: block;
}

.Select-LoadMoreText {
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding-left: 0px !important; 
}

.Select-LoadMoreText:hover {
    text-decoration: underline;
}

.Select-DropdownBox {
    background-color: var(--background-container);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 4px;
    overflow: auto;
    margin-top: 0.5rem;
    width: 100%;
    z-index: 3;
}

.Select-DropdownBox .SelectBox, .Select-DropdownBox .form-box, .Select-DropdownBox .form-box {
    padding-top: 4px;
    margin: 0 0 0.5rem 0;
}

.Select-DropdownBox > .select-options {
    max-height: 140px;
    overflow-y: auto;
}

.Select-DropdownBox .select-options > div {
    color: var(--input-color);
    cursor: pointer;
    padding: 0.75rem 0.75rem ;
    font-size: 0.9rem;
    border-top: 0.05px solid #eee;
}
.Select-DropdownBox .select-options > div:hover {
    background-color: rgb(238, 238, 238, 0.5);
    padding-left: 1rem;
}

.Select-DropdownBox .select-options > div.selected {
    background-color: rgb(238, 238, 238, 0.5);
    font-weight: 600;
}

.Select-DropdownBox .select-options > div:active {
    position: relative;
}

.Select-DropdownBox .select-options > div {
    align-items: center;
    display: flex;
}

.NewCategory-Btn-Container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
