 .SelectHealthPlaceUser {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 9999;
    background-color: white;
    opacity: 1;
}

.SelectHealthPlaceUser-Header {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
}

.SelectHealthPlaceUser-Main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    height: calc(100% - 60px);
}

.SelectHealthPlaceUser-Main-Grid {
    display: grid;
    grid-template-columns: repeat(2, 400px);
    grid-template-rows: repeat(2, 240px);
    row-gap: 1rem;
    column-gap: 1rem;
}

.SelectHealthPlaceUser-Main-Grid > div {
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 1rem;
}

.SelectHealthPlaceUser-Main-Grid > div:hover {
    border: 1px solid #bbb;
    cursor: pointer;
}

.SelectHealthPlaceUser-Main-Grid-Content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.25rem;
}

.SelectHealthPlaceUser-Main-Grid-Incomplete .SelectHealthPlaceUser-Main-Grid-Title {
    color: #bbb;
}

.SelectHealthPlaceUser-Main-Grid-Incomplete .SelectHealthPlaceUser-Main-Grid-Content {
    color: #bbb;
}

.SelectHealthPlaceUser-Main-Grid-Title {
    color: var(--primary-color);
}

.SelectHealthPlaceUser-BtnBox button {
    width: 300px;
}