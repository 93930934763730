.NextAppointment-Page{
    display: grid;
    grid-template-columns: repeat(3, 400px);
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    margin: 20px auto 0 auto;
    height: 100%;
}

.NextAppointment-EmptyMessage-Box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

.NextAppointment-EmptyMessage{
    color: #aaa;
}