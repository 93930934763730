.EditServiceLocationsOptionsModal {
    width: 500px;
}

.EditServiceLocationsOptionsModal-PersonalInfo {
    display: flex;
    flex-direction: column;
}

.EditServiceLocationsOptionsModal-Select .SelectBox, .EditServiceLocationsOptionsModal-Select .form-box, .EditServiceLocationsOptionsModal-Input .form-box {
    padding-top: 4px;
}

.EditServiceLocationsOptionsModal-Subtitle-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.EditServiceLocationsOptionsModal-Subtitle-Header-Icon {
    -webkit-box-align: center;
    background-color: #072AC8;
    border-color: #072AC8;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.EditServiceLocationsOptionsModal-Subtitle-Header-Title {
    font-weight: 600;
}

.EditServiceLocationsOptionsModal-CursorContainer {
    display: grid;
    grid-template-columns: 1fr min-content;
    column-gap: 1rem;
    margin-bottom: 1rem;
}

.EditServiceLocationsOptionsModal-CursorContainer .form-box {
    margin-bottom: 0px;
}

.EditServiceLocationsOptionsModal-CursorContainer-SaveBtn {
    margin-top: auto;
}

.EditServiceLocationsOptionsModal-HeaderRow-Action {
    text-align: right;
}

.EditServiceLocationsOptionsModal-Body-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 360px;
}

.EditServiceLocationsOptionsModal-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    column-gap: 0.5rem;
}

.EditServiceLocationsOptionsModal-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.EditServiceLocationsOptionsModal-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.ConfirmServiceLocationLookalikeSavingModal-CallToAction {
    margin-top: 2rem;
}

.ConfirmServiceLocationLookalikeSavingModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-top: 1rem;
}