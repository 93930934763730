.CurrentUserDropdown {
    position: relative;
}

.CurrentUserDropdown-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    color: var(--primary-font-color);
}

.CurrentUserDropdown-header:hover {
    background-color: rgb(255, 255, 255, 0.2);
    cursor: pointer;
}

.CurrentUserDropdown-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.CurrentUserDropdown-avatar > img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.CurrentUserDropdown-avatar > h5 {
    margin-block-start: 0;
    margin-block-end: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
}

.CurrentUserDropdown-NamesInfo {
    padding-left: 1rem;
}

.CurrentUserDropdown-NamesInfo span {
    color: rgba(var(--primary-font-color), 0.8);
}

.CurrentUserDropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-container, #fff);
    border: 1px solid var(--input-border-color, #ccc);
    border-radius: 0.5rem;
    min-width: 300px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: var(--text-color, #333);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
}

.CurrentUserDropdown-menu-profileInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
}

.CurrentUserDropdown-menu-profileInfo-email {
    font-weight: 400;
    margin-bottom: 2rem;
}

.CurrentUserDropdown-menu-profileInfo-avatar {
    display: flex;
    justify-content: center;
    align-content: center;
}

.CurrentUserDropdown-menu-profileInfo-avatar > img {
    border-radius: 50%;
    object-fit: cover;
    width: 5rem;
    height: 5rem;
}

.CurrentUserDropdown-menu-profileInfo-avatar > h5 {
    margin-block-start: 0;
    margin-block-end: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
}

.CurrentUserDropdown-menu-profileInfo-hi {
    font-size: 1rem;
    margin-top: 0.75rem;
    font-weight: 500;
}

.CurrentUserDropdown-menu-profileInfo > span {
    margin-top: 0.25rem;
    color: var(--text-color, #333);
    font-size: 0.9rem;
}

.CurrentUserDropdown-menu-select {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.CurrentUserDropdown-menu-select > span {
    display: block;
    font-weight: 500;
}

.CurrentUserDropdown-SelectBox {
    position: relative;
    margin-bottom: 4px;
    padding-top: 6px;
}

.CurrentUserDropdown-SelectBox label {
    color: var(--input-color, #333);
    display: block;
    font-size: 1em;
    background-color: var(--input-bg, #fff);
    box-shadow: 0px 0px 5px var(--input-bg, #fff);
    font-weight: bold;
    display: inline-block;
    padding: 0px 5px;
    position: absolute;
    top: 2px;
    left: 13px;
    z-index: 2;
}

.CurrentUserDropdown-select {
    width: 100%;
    padding: 11px 15px;
    font-size: 1em;
    border: 1px solid var(--input-border-color, #ccc);
    border-radius: 4px;
    background-color: var(--input-bg, #fff);
    color: var(--input-color, #333);
    cursor: pointer;
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none; 
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'%3E%3Cpath fill='%23333' d='M0 0l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px 5px;
}

.CurrentUserDropdown-select:focus {
    outline: none;
    border-color: var(--primary-color, #66afe9);
}

.CurrentUserDropdown-select.disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

.CurrentUserDropdown-select.error {
    border: 2px solid var(--error-bg, red);
}

.CurrentUserDropdown-select option {
    padding: 10px;
    font-size: 1em;
    color: var(--input-color, #333);
}

.CurrentUserDropdown-SelectBox {
    position: relative;
}

.CurrentUserDropdown-SelectBox::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    transform: translateY(-50%);
}

.CurrentUserDropdown-menu-item {
    padding: 0.75rem 1rem;
    color: var(--text-color, #333);
    cursor: pointer;
    font-size: 0.9rem;
}

.CurrentUserDropdown-menu-item:hover {
    background-color: #f0f0f0;
}

