.inactivate-user-modal {
	text-align: center;
	width: 400px;
}

.inactivate-user-warning {
	color: indianred;
	font-size: 18px;
	font-weight: bold;
	padding: 20px 0;
}

.inactivate-user-confirmation {
	font-weight: bold;
	padding: 20px 0;
}