.PatientInformation {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-top: 1rem;
}

.PatientInformation-Col {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    min-width: 400px;
    max-width: 500px;
}

.PatientInformation-Container {
    border: 1px solid #eee;
    padding: 1rem;
    width: 452px;
    height: min-content;
}

.PatientInformation-Actions{
    display: flex;
    gap: 10px;
    justify-content: center;
}

@media (max-width: 1000px) {
    .PatientInformation-Container {
        width: 400px;
    }
}