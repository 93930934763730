.confirm-merge-modal{
	text-align: center;
	width: 400px;
}

.confirm-merge-warning {
	color: indianred;
	font-size: 18px;
	font-weight: bold;
	padding: 20px 0;
}

.confirm-merge {
	font-weight: bold;
	padding: 20px 0;
}