.FilledScheduleMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* flex-wrap: wrap; */
    column-gap: 1rem;
    width: 100%;
}


.FilledScheduleMenu-TitleDiv {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
}

.FilledScheduleMenu-checkbox[type=checkbox] {
    appearance: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background-color: var(--primary-color-2);
    border: 1px solid var(--primary-color);
    border-radius: 0.2em;
    transition: .2s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FilledScheduleMenu-checkbox[type=checkbox]:checked::before {
    content: "\2714";
    position: absolute;
    color: #fff;
}

.FilledScheduleMenu-checkbox[type=checkbox]:checked {
    background-color: var(--primary-bg);
    border-color: var(--primary-bg);
}

.FilledScheduleMenu-HourTime {
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
}

.FilledScheduleMenu-Dropdown {
    position: relative;
    display: inline-block;
}

.FilledScheduleMenu-Dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1 !important;
    white-space: nowrap;
    border-radius: 8px;
    color: #aaa;
}

.FilledScheduleMenu-Dropdown-content-weekly {
    left: 70%;
    transform: translateX(-50%);
    z-index: 1 !important;
}

.FilledScheduleMenu-Dropdown-left {
    left: auto;
    right: 70%;
    transform: translateX(0);
    z-index: 1 !important;
}

.FilledScheduleMenu-Dropdown-ActionBar {
    display: flex;
}

.FilledScheduleMenu-Dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    fill: #aaa;
}

.FilledScheduleMenu-Dropdown-item:hover {
    background-color: #f1f1f1;
    fill: var(--primary-color);
}

.FilledScheduleMenu-DropDown-info {
    margin-left: 16px;
}