label.uploadfileInputSecondary {
  display: inline-block;
  padding: 12px;
  min-width: 150px;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  height: 44px;
  text-align: center; 
  line-height: 28px;
  margin-bottom: 1rem;
  border: 1px solid #eee; 
  background-color: #eee;
  color: #444;
  font-weight: 600;
}

label.uploadfileInputSecondary:hover {
  background-color: #ddd;
}

label.uploadfileInputSecondary:active {
  background-color: #bbb;
}

label.uploadfileInputSecondary.disabled {
  border: 1px solid #ddd; 
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

.bg-img-default {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-img-default > div {
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.bg-img-default > img {
  display: flex;
  position: relative;
}

.painel-img-cropper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media ( max-width: 920px ) {
  .painel-img-cropper {
    flex-direction: column;
  }
}