.ChatWindow {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #fff;
    border-radius: 8px 0 0 8px;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    transition-property: right;
    transition-duration: 0.3s;
    z-index: 1;
    width: min(40vw, 500px);
}

.ChatWindow-Header {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding-bottom: 20px;
}

.ChatWindow.open {
    right: 0;
  }

.ChatWindow.close {
    right: -40%;
  }

.ChatWindow-CloseBtn-Box {
    width: 100%;
    display: flex;
    justify-content: start;
    margin: 20px 0px 0px 20px;
}

.ChatWindow-CloseBtn {
    border: none;
    background-color: transparent;
    padding: 0;
    font-weight: bold;
    font-size: large;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 0.2s ease;
}

.ChatWindow-CloseBtn:hover {
    opacity: 0.3;
}

.ChatWindow-Title-Div {
    display: flex;
    align-items: center;
    /* position: relative; */
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    box-sizing: border-box;
    
}

.ChatWindow-Title {
    text-align: center;
    /* margin: auto; */
    font-size: 1.8rem;
    line-height: 1.8rem;
}

.ChatWindow-AddBtn {
    /* width: 40px; */
    /* height: 40px; */
    /* position: absolute; */
    /* left: 0; */
    padding: 2px;
    margin-left: 20px;
    margin-top: 5px;
    border: 2px solid black;
    border-radius: 5px;
    font-size: x-large;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-color 0.2s ease, color 0.2s ease ;
}

.ChatWindow-AddBtn:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.ChatWindow-Actions-Div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.ChatWindow-Actions-Notification{
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 0.2s ease;
}

.ChatWindow-Actions-Notification:hover{
    opacity: 0.3;
}

.ChatWindow-Actions-Search {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 65%;
    position: relative;
}

.ChatWindow-Actions-Search-Input-Box {
    width: 100%;
}

.ChatWindow-Actions-Search-Icon{
    position: absolute;
    right: 10px;
    background-color: #fff;
    padding: 5px 2px;
}

.ChatList {
    width: 100%;
    overflow-y: auto;
    position: relative;
}

.ChatList-Item-Wrapper:first-child .ChatList-Item-MoreOptions.group{
    top: 10px;
}

.Chatlist-Loading-Div {
    width: 100%;
    height: 150px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ChatList-Loader {
    /* HTML: <div class="loader"></div> */
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }

  @keyframes l5 {
      0%  {box-shadow: 20px 0 #aaa, -20px 0 #aaa2;background: #aaa }
      33% {box-shadow: 20px 0 #aaa, -20px 0 #aaa2;background: #aaa2}
      66% {box-shadow: 20px 0 #aaa2,-20px 0 #aaa; background: #aaa2}
      100%{box-shadow: 20px 0 #aaa2,-20px 0 #aaa; background: #aaa }
  }


/* ScrollBar Style */
.ChatList::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.ChatList::-webkit-scrollbar-track {
    background: transparent; 
}

.ChatList::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.ChatList::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.ChatList-NoMessages {
    color: #aaa;
    text-align: center;
    margin-top: 1.5rem;
}