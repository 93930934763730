.MaterialFlowModal {
    display: flex;
    flex-direction: column;
    width: 1024px;
}

.MaterialFlowModal-Input-W{
    width: 100%;
    grid-column: span 1;
}

.MaterialFlowModal-NewSelect-Node {
    padding: 0.5rem;
}

.MaterialFlowModal-Input-FullRow{
    grid-column: span 4;
}

.MaterialFlowModal-Input-2Col {
    grid-column: span 2;
}

.MaterialFlowModal-Asterisk {
    color: rgb(236, 106, 106);
}

.MaterialFlowModal-Form-Body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.MaterialFlowModal-Form-Inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
}

.MaterialFlowModal-Form-Body-ExpirationDate {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template-columns: 94px 112px 94px;
}

.MaterialFlowModal-Form-ImgBox {
    width: 100%;
    display: flex;
    justify-content: center;
}

.MaterialFlowModal-Form-ActionRow {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 0.5rem;
}

.MaterialFlowModal-Form-ActionRow-DeleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.MaterialFlowModal-Form-ActionRow-BtnBox {
    display: flex;
    column-gap: 0.5rem;
}

.MaterialFlow-EntryForm, .MaterialFlow-ExitForm{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
}

.MaterialFlow-EntryForm-BtnBox {
    grid-column: span 4;
    display: flex;
    justify-content: end;
    gap: 5px;
}

.MaterialFlow-Division {
    border-top: 1px solid #eee;
    margin: 20px 0;
}