.death-record-modal{
    width: 400px;
}


.death-record-cids-selected{
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.death-record-cid-item{
     border: 1px black solid;
     border-radius: 10px;
     padding: 5px;
}