.patientNewDocumentFrame {
    width: 100%; 
    height: 100%; 
    border: none; 
    margin: 0; 
    padding: 0; 
    display: block;
}

.patientNewDocumentBox {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}