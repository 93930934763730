.NewThousandDaysProject {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    min-height: 560px;
    width: 500px;
}

.NewThousandDaysProject-BtnBox {
    display: flex;
    margin-top: 1rem;
    column-gap: 0.5rem;
    justify-content: flex-end;
}
