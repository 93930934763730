.ManageTeamEdgesModal {
    width: 800px;
}

.ManageTeamEdgesModal-Wrapper {
    background-color: #eee;
    height: 1px;
    margin: 1rem 0rem;
}

.ManageTeamEdgesModal-List {
    height: 300px;
}

.ManageTeamEdgesFullWidth-List {
    height: calc(80vh - 240px);
}

.ManageTeamEdgesModal-NewSelect {
    padding: 0.5rem;
}

.ManageTeamEdgesModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.ManageTeamEdgesModal-ActionRow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
}

.ManageTeamEdgesModal-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.ManageTeamEdgesModal-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}