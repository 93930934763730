.DeletePhysicalAssessment {
    width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.DeletePhysicalAssessment-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}