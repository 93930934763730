.PasswordData-InputRow {
	margin-top: 1.5rem;
	display: flex;
	column-gap: 1rem;
	flex-wrap: wrap;
}

.PasswordData-InputRow > div {
	min-width: 280px;
}

.PasswordData-BtnRow {
	margin-top: 0.5rem;
	display: flex;
	column-gap: 1rem;
}

.PasswordData-BtnBox {
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

.PasswordData-BtnBox div button {
	width: 100%;
}
