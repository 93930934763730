
.ManageAuthenticationPage {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    min-height: 450px;
    background-color: var(--background-container);
}

.ManageAuthenticationPage > section {
    max-width: 450px;
    width: 100%;
    border: rgb(219, 219, 219);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 4rem 2rem 2rem;
    position: relative;
    background-color: var(--background-container);
    border-radius: 4px;
}

.ManageAuthenticationPage-Title {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-color);
}

.ManageAuthenticationPage-Headline {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
}

.ManageAuthenticationPage-Headline > h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}


.ManageAuthenticationPage-BtnFullWidth button {
    width: 100%;
}

@media (max-width: 576px) {
    .ManageAuthenticationPage section {
        border: unset;
        box-shadow: unset;
    }
}