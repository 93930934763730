.PreceptorshipManageRoom {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
    min-height: calc(100vh - 40px);
}

.PreceptorshipManageRoom-BtnFullWidth button {
	width: 100%;
}

.PreceptorshipManageRoom-Filters-Separator {
	margin: 1rem;
	background-color: #eee;
	height: 1px;
}

.PreceptorshipManageRoom-Filters-Select-Date {
	display: grid;
	column-gap: 4px;
	grid-column-gap: 4px;
	max-width: 100%;
	grid-template-columns: 80px 112px 80px;
}

.PreceptorshipManageRoom-Separator {
    width: 1px;
    background-color: #eee;
}

.PreceptorshipManageRoom-Main-Grid {
    display: flex;
	flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
	margin-top: 1.5rem;
}

.PreceptorshipManageRoom-Main-Grid > div {
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 1rem;
	height: 260px;
	width: 380px;
}

.PreceptorshipManageRoom-Main-Grid > div:hover {
    border: 1px solid #bbb;
    cursor: pointer;
}

.PreceptorshipManageRoom-Main-Grid-Content {
    display: grid;
    row-gap: 0.25rem;
}

.PreceptorshipManageRoom-Main-Grid-Title {
    color: var(--primary-color);
}

.PreceptorshipManageRoom-Main-Warning {
	cursor: pointer;
}

.PreceptorshipManageRoom-Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
}