.GlassesPrescription-Row {
    display: flex;
    column-gap: 0.5rem;
}

.GlassesPrescription-Paragraph {
    margin-top: 1rem;
}

.GlassesPrescription-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
}

.GlassesPrescription-ActionRow {
	margin: 1rem 0 0.75rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.GlassesPrescription-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
}

.GlassesPrescription-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.GlassesPrescription-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.GlassesPrescription-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}