.ABOGroupRHFactorWrite-DataRow {
    display: grid;
    grid-template-columns: 140px 140px;
    column-gap: 2rem;
    margin-bottom: 2rem;
}

.ABOGroupRHFactorWrite-Date {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 80px 120px 80px;
}