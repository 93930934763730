.TemplateTypeSection {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 800px;
}

.TemplateTypeSection-Manager {
    display: flex;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    flex-wrap: wrap;
}

.TemplateTypeSection-Manager-Instance {
    height: 180px;
    width: 180px;
    border-radius: 1rem;
    border: 4px solid #eee;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    flex-direction: column;
}

.TemplateTypeSection-Manager-Instance:hover {
    border: 4px solid #ddd;    
}

.TemplateTypeSection-Manager-Instance.active {
    border: 4px solid var(--primary-color);    
}

.TemplateTypeSection-Manager-Instance-File {
    position: relative;
    width: 140px;
    height: 120px;
    background-color: #fff;
    border-radius: 4px 4px 0px 0px;
    /*border: 1px solid #bbb;*/
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
/*  
.TemplateTypeSection-Manager-Instance-File-TopLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 20px 0, 0 20px);
    background-color: var(--primary-color);
    border-bottom-right-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
*/
.TemplateTypeSection-Manager-Instance-Title {
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
}

.TemplateTypeSection-Manager-Instance-Subtitle {
    margin-top: 1.75rem;
    text-align: center;
    color: #aaa;
    font-size: 0.7rem;
    font-weight: 500;
}

.TemplateTypeSection-ActionRow {
	margin: 0 0 0.75rem;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.TemplateTypeSection-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
	font-weight: bolder;
	color: #bbb;
}

.TemplateTypeSection-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.TemplateTypeSection-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.TemplateTypeSection-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.TemplateTypeSection-AltActionBox {
    display: flex;
    width: min-content;
    column-gap: 0.75rem;
}

.TemplateTypeSection-AltActionBox-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    color: #333333;
    fill: #333333;
    text-wrap: nowrap;
}

.TemplateTypeSection-AltActionBox-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.TemplateTypeSection-BtnBox {
    display: flex;
    justify-content: flex-end;
}

.TemplateTypeSection-Paginate .pagination-custom {
    margin: 0;
}

.TemplateTypeSection-BottomRow {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-top: 1rem;
  }