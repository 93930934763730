.ProductTypesOptions {
    width: 100%;
}

.ProductTypesOptions-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
}

.ProductTypesOptions-Header-Icon {
    -webkit-box-align: center;
    background-color: #463F3A;
    border-color: #463F3A;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.ProductTypesOptions-Header-Title {
    font-weight: 600;
}

.ProductTypesOptions-ActionRow {
    margin-top: 1rem;
    display: flex;
    column-gap: 0.5rem;
}

.ProductTypesOptions-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    color: #333333;
    fill: #333333;
}

.ProductTypesOptions-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}