.LoginDentistModal {
    width: 330px;
    text-align: center;
}

.LoginDentistModal-VerificationCode {
    margin-top: 0.5rem;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(6, 40.5px);
    column-gap: 0.5rem;
}

.LoginDentistModal-SendAgain {
    margin-top: 2rem;
    line-height: 0;
}

.LoginDentistModal-SendAgain span {
    color: var(--primary-color-dark);
    cursor: pointer;
}

.LoginDentistModal-SendAgain span:hover {
    text-decoration: underline;
    color: var(--primary-color);
}