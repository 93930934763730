.MedicalRecordHistory-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.MedicalRecordHistory-AccordionNav {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),0 -1px rgba(0, 0, 0, 0.1) inset,0 2px 1px -1px rgba(255, 255, 255, 0.5) inset;
    width: 99.9%;
    background-color: #EEE;
}

.MedicalRecordHistory-AccordionNav > svg {
    width: 12px;
    fill: black;
}

.MedicalRecordHistory-AccordionNav.open > svg {
    transform: rotate(180deg);
}

.MedicalRecordHistory-Section {
    transition: opacity 0.5s ease, height 0.5s ease;
    opacity: 0;
    height: 0;
}

.MedicalRecordHistory-Section.open {
    margin-top: 0.5rem;
    opacity: 1;
    height: auto;
}

.MedicalRecordHistory-ActionRow {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
}

.MedicalRecordHistory-ActionRow svg {
    font-size: 0.8rem;
    height: 14px;
    width: 14px;
}

.MedicalRecordHistory-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
}

.MedicalRecordHistory-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.MedicalRecordHistory-Body {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.MedicalRecordHistory-Separator {
    width: 1px;
    min-height: 50vh;
    background-color: #eee;
}

.MedicalRecordHistory-RecordUnitWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding-bottom: 1rem;
}
