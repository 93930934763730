.Conversation {
    width: 22rem;
    height: 26rem;
    margin-right: 0.5rem;
    position: fixed;
    bottom: 0;
    right: calc(min(40vw, 500px));
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px #bbb solid;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    /* animacao slideup */
    /* transform: translateY(0);  */
    opacity: 1; 
    animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

.Conversation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15%;
    background-color: var(--primary-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.Conversation-Title-Box{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    overflow: hidden;
}


.Conversation-Title{
    margin: 0;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}

.Conversation-Dialog-Info {
    position: absolute;
    right: 0;
    top: 2rem;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 0.5rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.Conversation-CloseBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.Conversation-EditBtn:hover {
    opacity: 0.8;
}

.Conversation-EditBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
    flex-shrink: 0;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out; 
}

.Conversation-Chat {
    padding: 10px 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    margin: 5px;
}

/* ScrollBar Style */
.Conversation-Chat::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.Conversation-Chat::-webkit-scrollbar-track {
    background: transparent; 
}

.Conversation-Chat::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.Conversation-Chat::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
.Conversation-LoadConversationBtn {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
    transition: background-color 0.3s ease;
}

.Conversation-LoadConversationBtn:hover {
    background-color: var(--primary-color);
}

.Conversation-Chat-Date-Separator {
    font-family: "Roboto";
    font-size: 14px;
    text-align: center;
    color: #aaa;
    margin: 10px 0;
}

.Conversation-Date-Messages {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Conversation-Chat-MessageBox {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.MyMessage {
    align-self: flex-end;
    text-align: right;
    background-color: var(--primary-color);
    color: white;
}

.OthersMessage {
    align-self: flex-start;
    text-align: left;
    background-color: #ddd;

}

.Conversation-Chat-Message {
    width: auto;
    max-width: 70%;
    border-radius: 8px;
    padding: 10px;
    word-wrap: break-word;
    word-break: break-word;
}

.Conversation-Chat-InfoMessage {
    font-size: 0.8rem;
    color: #aaa;
}

.MyInfoMessage {
    align-self: flex-end;
    text-align: right;
}

.OthersInfoMessage {
    align-self: flex-start;
    text-align: left;
}

.Conversation-Input-Box {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 10px 10px;
    border-bottom: 1px solid #bbb;
}

.Conversation-Input {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.Conversation-Input textarea {
  resize: none;
  border: none;
  border-style: none;
  border-radius: 0.4rem;
  font-size: 14px;
  padding: 10px;
  height: 36px;
  font-family: Arial, Helvetica, sans-serif;
  flex-grow: 1;
  overflow: hidden;
}

.Conversation-SendBtn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}