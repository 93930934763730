.Birthforecast-Subtitle {
    font-weight: 900;
    font-size: 0.95rem;
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color);
}

.Birthforescast-DateRow {
    display: grid;
    grid-template-columns: 5rem 7.5rem 5rem;
    column-gap: 0.5rem;
}