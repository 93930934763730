.PreventiveExams {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.PreventiveExams-Grid {
    display: flex;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}

.PreventiveExams-Select .SelectBox, .PreventiveExams-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.PreventiveExams-Select div, .PreventiveExams-Input div {
    width: 160px;
}

.PreventiveExams-Grid {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 2rem;
    flex-wrap: wrap;
}