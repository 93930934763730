.BookingAppointmentCursor {
    height: 20px;
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 1rem;
}

.TimeSchedule-Wrapper {
    position: absolute;
    left: 330px;
    width: calc(100% - 330px);
    top: -5px;
}

.BookingAppointmentCursor-ActionButtonsArea-SelectedTimes {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    margin-bottom: 1rem;
}

.BookingAppointmentCursor-ActionButtonsArea {
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
    margin-bottom: 0.5rem;
}

.BookingAppointmentCursor-ActionButtonsArea button {
    width: 100%;
    margin: 0rem !important;
}

.BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    width: 100%;
    color: #333333;
}

.BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.BookingAppointmentCursor-ActionButtonsArea-ActionRow-Btn svg {
    width: 16px;
    height: 16px;
    fill: #333333;
}

/*
.HealthPlaceUserUnitWrapper-HoldingInfo-BtnFullWidth button {
	width: 100%;
}
*/

.SelectedTimesCursorSlot-TimeRow-Wrapper {
    height: 180px;
    overflow-y: auto;
}

/* ScrollBar Style */
.SelectedTimesCursorSlot-TimeRow-Wrapper::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
}

.SelectedTimesCursorSlot-TimeRow-Wrapper::-webkit-scrollbar-track {
    background: transparent; 
}

.SelectedTimesCursorSlot-TimeRow-Wrapper::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px; 
}

.SelectedTimesCursorSlot-TimeRow-Wrapper::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.SelectedTimesCursorSlot-TimeRow {
    display: flex;
    row-gap: 0.25rem;
    column-gap: 0.25rem;
    flex-wrap: wrap;
}

.SelectedTimesCursorSlot-TimeRow>div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.25rem;
}

.SelectedTimesCursorSlot-TimeRow>div:hover {
    cursor: pointer;
    background-color: #F95C5C;
    color: white;
}

.SelectedTimesCursorSlot-TimeRow-Instance-Close {
    margin-left: 4px;

}

.BookingAppointmentCursor-NotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    fill: #aaa;
    color: #aaa;
    height: calc(100vh - 320px);
}

.BookingAppointmentCursor-NotFound>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    padding: 3rem;
}

.BookingAppointmentCursor-NotFound svg {
    width: 52px;
    height: 52px;
}

.BookingAppointmentCursor-NotFound p {
    font-size: 1rem;
}

.BookingAppointmentCursor-SideContainer {
    min-width: 300px;
}