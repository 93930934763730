.ListRequestMaterials {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
}

.ListRequestMaterials-NewSelect {
    padding: 0.5rem;
}

.ListRequestMaterials-Filters-Body {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.ListRequestMaterials-Filters-Body > div {
    width: 240px;
}

.ListRequestMaterials-Filters-Body > div.ListRequestMaterials-SelectRequester {
    width: 300px;
}

.ListRequestMaterials-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}

.ListRequestMaterials-Success {
    border: 1px solid #14A44D;
    color:#14A44D;
    background-color: #e8fcf0;
}

.ListRequestMaterials-Error {
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
}

.ListRequestMaterials-HeaderRow-Action {
    text-align: right;
}

.ListRequestMaterials-ActionRow {
    display: flex;
    align-items: center;
    height: 20px;
    justify-content: flex-end;
}

.ListRequestMaterials-ActionRow > div > svg {
    width: 12px;
    fill: #bbb;
    cursor: pointer;
    height: 12px;
}

.ListRequestMaterials-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}