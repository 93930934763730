.CallPatientModal {
    width: 500px;
}

.CallPatientModal-NewSelect .SelectBox, .CallPatientModal-NewSelect .form-box, .CallPatientModal-Input .form-box {
    padding-top: 4px;
}

.CallPatientModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.CallPatientModal-Asterisk {
    color: rgb(236, 106, 106);
}
