.NewEditAnthropometry {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.NewEditAnthropometry-MainRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
}

.NewEditAnthropometry-MainRow-Input {
    width: 120px;
}

.NewEditAnthropometry-IMCCalculator {
    display: grid;
    grid-template-columns: 96px 96px 168px;
    column-gap: 1rem;
}

.NewEditAnthropometry-Pressure {
    display: grid;
    grid-template-columns: 96px 96px;
    column-gap: 1rem;
}

.NewEditAnthropometry-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}