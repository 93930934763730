.ListInventoryMaterials {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
}

.ListInventoryMaterials-Select .SelectBox, .ListInventoryMaterials-Select .form-box, .ListInventoryMaterials-Input .form-box {
    padding-top: 4px;
}

.ListInventoryMaterials-Filters-Body {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.ListInventoryMaterials-Filters-Body > div {
    width: 200px;
}

.ListInventoryMaterials-Status-Subtitle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
    white-space: nowrap;
}

.ListInventoryMaterials-Status-Subtitle-Error {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color-light-2);
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
    border: 1px solid #f95c5c;
    color:#f95c5c;
    background-color: #fee6e6;
    white-space: nowrap;
}

.ListInventoryMaterials-HeaderRow-Action {
    text-align: right;
}

.ListInventoryMaterials-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
}

.ListInventoryMaterials-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.ListInventoryMaterials-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}


@media (max-width: 1200px) {
    .ListInventoryMaterials-List-Presentation {
        display: none;
    }
}

@media (max-width: 1000px) {
    .ListInventoryMaterials-List-Category {
        display: none;
    }
}

@media (max-width: 800px) {
    .ListInventoryMaterials-List-Code {
        display: none;
    }
}
