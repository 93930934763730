.HealthPlaceForm-Body{
    gap: 10px;
}

.HealthPlaceGeneralData-Form-2Column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.Title-Asterisk{
    color: rgb(236, 106, 106);
}

.HealthPlaceForm-end-container{
    display: flex;
    flex-direction: column;
    align-items: last baseline;
}