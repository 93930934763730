.tabs {
    width: 100%;
    margin-top: 10px;
}

.tabs-headers {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.tab-name {
    flex: 1;
    padding: 10px;
    background-color: #eee;
    /* margin: 0 1px; */
    font-weight: bold;
    height: 50px;
    /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #eee;
}

.tab-name:hover {
    background-color: #fefefe;
    box-shadow: 0px -2px 4px -2px #aaa;
}

.tab-name.selected {
    background-color: white;
    color: var(--primary-color);
    height: 52px;
    position: relative;
    bottom: 2px;
    box-shadow: 1px -2px 4px -2px #aaa;
    border-bottom: 0;
    border-bottom: 2px solid var(--primary-color);
}

.tabs-content {
    /* height: calc(100% - 500px); */
}

@media (max-width: 500px) {
    .tabs {
        margin-top: 0px;
    }
}