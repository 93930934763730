.WaitingRoomAttendancesFilters-ActionRow {
	margin: 0.5rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
}

.WaitingRoomAttendancesFilters-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
}

.WaitingRoomAttendancesFilters-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.WaitingRoomAttendancesFilters-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.WaitingRoomAttendancesFilters-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.WaitingRoomAttendancesFilters-Separator {
	width: 100%;
	min-height: 1px;
	background-color: #eee;
	margin: 12px 0;
}

.WaitingRoomAttendancesFilters-SideBarLeft {
	display: flex;
	flex-direction: column;
}

.WaitingRoomAttendancesFilters-AttendanceDateDiv {
	display: flex;
	column-gap: 0.5rem;
}

.WaitingRoomAttendancesFilters-NewMultiSelect-FilterNode {
    padding: 0rem 0.25rem;
}