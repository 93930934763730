.EditChatModal {
    width: 400px;
    z-index: 9999;
}

.EditChatModal-Field-Label {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0;
    overflow: hidden;
    margin: 0 0 10px 0;
    gap: 5px;

}
.EditChatModal-Field-Label p {
    margin: 0;
    line-height: 1rem;
}

.EditChatModal-Field-Label span {

}

.EditChatModal-ParticipantsList-Label {
    font-weight: 600;
}

.EditChatModal-ParticipantsList {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
    margin: 15px 0 15px 0;
    max-height: 150px; 
    overflow-y: auto; 
    box-sizing: border-box;
    margin-right: 1rem;
}

.EditChatModal-ParticipantsList-Item {
    padding: 0.3rem 1.8rem 0.3rem 0.8rem;
    border: none;
    border-radius: 16px;
    background-color: #ddd;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.EditChatModal-ParticipantsList-Item-IconBox {
    display: flex;
    align-items: center;
    justify-items: center;
    position: absolute;
    right: 5px;
    width: 1rem;
    cursor: pointer;
}

.EditChatModal-ParticipantsList-Item-CloseIcon:hover {
    color: #aaa;
}

.EditChatModal-ActionBox {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.EditChatModal-ParticipantsList-Label {
    font-weight: 600;
}

/* ScrollBar Style */
.EditChatModal-ParticipantsList::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.EditChatModal-ParticipantsList::-webkit-scrollbar-track {
    background: transparent;
}

.EditChatModal-ParticipantsList::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.EditChatModal-ParticipantsList::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
