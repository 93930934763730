.AlertRowText {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.AlertRowText-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
}

.AlertRowText-span {
    font-weight: 600;
    font-size: 0.9rem;
    word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

.AlertRowText-success > div {
    color: #14A44D;
}

.AlertRowText-error > div {
    color: #F95C5C;
}

.AlertRowText-info > div {
    color: #aaa;
}

.AlertRowText-Outlined {
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 4px;
}

.AlertRowText-Outlined.AlertRowText-success {
    border: 1px solid #14A44D;
    background-color: #f3fef7;
}

.AlertRowText-Outlined.AlertRowText-error {
    border: 1px solid #F95C5C;
    background-color: #fff7f7;
}

.AlertRowText-Outlined.AlertRowText-info {
    border: 1px solid #aaa;
    background-color: #fbfbfb;
}