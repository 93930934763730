.GeneralInformation {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.GeneralInformation-Select .SelectBox, .GeneralInformation-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.GeneralInformation-RHFactor {
    display: grid;
    grid-template-columns: 140px 140px;
    column-gap: 2rem;
}

