.RecoverPassword {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	min-height: 450px;
	background-color: var(--background-container);
}

.RecoverPassword > section > h3 {
	margin-bottom: 3rem;
	text-align: center;
	font-size: 3.5rem;
	color: var(--primary-bg);
}

.RecoverPassword section {
	max-width: 400px;
	width: 100%;
	border: rgb(219, 219, 219);
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	padding: 4rem 2rem 2rem;
	position: relative;
	border-radius: 4px;
}

.RegisterPage section h4 {
	text-align: center;
	color: var(--text-color);
	margin-bottom: 1.5rem;
	font-size: 1.3rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.RecoverPassword-Headline {
	text-align: center;
	color: var(--text-color);
	margin-bottom: 1.5rem;
}

.RecoverPassword-Headline > h4 {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.RecoverPassword-BtnBox {
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

.RecoverPassword-BtnBox div button {
	width: 100%;
}

@media (max-width: 576px) {
	.RecoverPassword section {
		max-width: 100%;
		border: unset;
		box-shadow: unset;
	}
}
