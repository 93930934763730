.HealthPlaceInstitutionalAppearanceModal {
    width: 500px;
}


.HealthPlaceInstitutionalAppearanceModal-Header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.HealthPlaceInstitutionalAppearanceModal-Header-Icon {
    -webkit-box-align: center;
    background-color: #70A9A1;
    border-color: #70A9A1;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.HealthPlaceInstitutionalAppearanceModal-Header-Title {
    font-weight: 600;
}

.HealthPlaceInstitutionalAppearanceModal-ShadesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 1rem;
}

.HealthPlaceInstitutionalAppearanceModal-Title-Box {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.HealthPlaceInstitutionalAppearanceModal-Title{
    margin: 0;
}

.HealthPlaceInstitutionalAppearanceModal-Inf-Container {
    background-color: #f1f1f1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    position: absolute;
}

.HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(199, 199, 199);
}

.HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome {
    position: relative;
    top: 40px;
    display: none;
    z-index: 10;
}


.HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor:hover {
    cursor: pointer;
}

.HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor:hover .HealthPlaceInstitutionalAppearanceModal-ShadesContainer-Cursor-Chrome {
    display: block;
    cursor: pointer;
}

.label-disabled {
    color: rgb(184, 184, 184);
}

.HealthPlaceInstitutionalAppearanceModal-ActionBox{
    display: flex;
    flex-direction: column;
    align-items: last;
    gap: 20px;
    margin-top: 1.5rem;
}

.HealthPlaceInstitutionalAppearanceModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}