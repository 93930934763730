.NewEvaluation {
    height: 80vh;
    width: 80vw;
    min-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NewEvaluation-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

.NewEvaluation-Body-Select {
    width: 300px;
    margin-bottom: 2rem;
}

.NewEvaluation-Body-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NewEvaluation-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-top: 1rem;
}