.ConsultationHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 0.25rem;
}

.ConsultationHeader-LeftColumn {
    /*border-bottom: 1px solid #eee;*/
    margin-bottom: 1rem;
    display: flex;
    column-gap: 3rem;
    padding: 0 0.5rem;
    flex-wrap: wrap;
    row-gap: 1.5rem;
}

.ConsultationHeader-LeftColumn-Profile {
    display: flex;
    column-gap: 0.5rem;
}

.ConsultationHeader-LeftColumn-Profile-TitleText {
    text-transform: capitalize;
    font-weight: 600;
    color: #333333;
    line-height: 8px;
    font-size: 1rem;
    text-wrap: nowrap;
}

.ConsultationHeader-LeftColumn-Profile-AltText {
    color: #999999;
    font-weight: 400;
    font-size: 0.9rem;
    align-items: center;
    text-wrap: nowrap;
}

.ConsultationHeader-LeftColumn-Profile-AltText-Outlined {
    padding: 2px 8px;
    background-color: #eee;
    color: #888888;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    margin-right: 0.25rem;
}

.ConsultationHeader-LeftColumn-Profile-Avatar {
    display: flex;
    flex-direction: row;
}

.ConsultationHeader-LeftColumn-Profile-Avatar > .img {
    border: 1px solid #eee;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.ConsultationHeader-LeftColumn-Profile-Avatar > .img > .icon-user {
    font-size: 1.5rem;
    color: var(--primary-bg);
}

.ConsultationHeader-LeftColumn-Profile-Avatar-Img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.ConsultationHeader-LeftColumn-RightBox {
    display: flex;
    column-gap: 3rem;
}

.ConsultationHeader-LeftColumn-InfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}    

.ConsultationHeader-LeftColumn-InfoBox-TitleText {
    text-transform: capitalize;
    font-weight: 400;
    color: #aaa;
    line-height: 12px;
    text-wrap: nowrap;
}

.ConsultationHeader-LeftColumn-InfoBox-AltText {
    font-size: 0.9rem;
    color: #aaa;
    font-weight: 900;
    text-wrap: nowrap;
}

.ConsultationHeader-LeftColumn-InfoBox-AltText-Instances {
    font-size: 0.9rem;
    color: #aaa;
    font-weight: 900;
    text-wrap: nowrap;
    display: flex;
    column-gap: 0.5rem;
    cursor: pointer;
}

.ConsultationHeader-LeftColumn-InfoBox-AltText-Instances span {
    border: 1px solid var(--primary-color);
    background-color: #E3F0FF;
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
}

.ConsultationHeader-RightColumn {
    display: flex;
    align-items: center;
}

.ConsultationHeader-RightColumn-ActionRow {
	margin: 0.5rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
    position: relative;
    bottom: 10px;
}

.ConsultationHeader-RightColumn-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
}

.ConsultationHeader-RightColumn-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.ConsultationHeader-RightColumn-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.ConsultationHeader-RightColumn-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}

.ConsultationHeader-RightColumn-ActionRow-SendIcon {
    width: 1.2rem;
    fill:  #FFFFFF;
    cursor: pointer;
    rotate: -45deg;
}

.ConsultationHeader-CidTenField-Menu {
    position: absolute;
    top: 60px;
    background-color: var(--background-container, #fff);
    border: 1px solid var(--input-border-color, #ccc);
    border-radius: 0.5rem;
    min-width: 300px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: var(--text-color, #333);
    display: flex;
    padding: 1.5rem;
    display: none;
}

.ConsultationHeader-CidTenField-Menu-Instances {
    font-size: 0.9rem;
    color: #aaa;
    font-weight: 900;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    column-gap: 0.5rem;
    cursor: pointer;
    row-gap: 0.5rem;
}

.ConsultationHeader-CidTenField-Menu-Instances span {
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    width: min-content;
}

.ConsultationHeader-LeftColumn-InfoBox:hover > .ConsultationHeader-CidTenField-Menu {
    display: flex;
}

.ShareVideoCallTokenModal {
    width: 400px;
}

.ShareVideoCallTokenModal-BtnBox {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
    margin-top: 3rem;
}

.ShareVideoCallTokenModal-BtnBox button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
