.CreateSchedulesModal-Asterisk {
    color: rgb(236, 106, 106);
}

.CreateSchedulesModal-Error {
    margin-top: 1rem;
    color: rgb(236, 106, 106);
    font-size: 11px;
    line-height: 0;
}

.CreateSchedulesModal-Input {
    cursor: pointer;
}

.CreateSchedulesModal-Select .SelectBox, .CreateSchedulesModal-Input .form-box {
    padding-top: 4px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.CreateSchedulesModal {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 640px;
    min-height: 300px;
}

.CreateSchedulesModal-HourCalculator-InputGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
}
.CreateSchedulesModal-DaysOfTheWeek {
    display: flex;
    column-gap: 0.25rem;
    margin-top: 0.5rem;
}

.CreateSchedulesModal-RepeatFrequency {
    margin-bottom: 1rem;
}

.CreateSchedulesModal-InputRow {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.CreateSchedulesModal-InputRow .form-box {
    padding: 0rem;
    margin: 0rem;
    width: 72px;
}

.CreateSchedulesModal-DaysOfTheWeek > div {
    align-items: center;
    background-color: rgb(241, 243, 244);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-sizing: border-box;
    color: rgb(112, 117, 122);
    color-scheme: light;
    cursor: pointer;
    display: inline-flex;
    font-family: Roboto, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    height: 24px;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin-right: 8px;
    pointer-events: auto;
    text-rendering: geometricprecision;
    text-transform: none;
    width: 24px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
}

.CreateSchedulesModal-DaysOfTheWeek > div:hover {
    opacity: 0.7;
}

.CreateSchedulesModal-DaysOfTheWeek > div.active {
    background-color: var(--primary-color);
    color: white;
}

.CreateSchedulesModal-DateGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
}

.CreateSchedulesModal-DateSelect {
    display: grid;
    column-gap: 4px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template-columns: 80px 112px 80px;
}

.CreateSchedulesModal-BtnBox {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem
}