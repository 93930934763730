.ConfigurationPage {
    display: flex;
    flex-direction: column;
}

.ConfigurationPage-Content {
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 2rem;
    height: 100%;
    flex-grow: 1;
}

.ConfigurationPage-Loading {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ConfigurationPage-sideleft-btn {
    display: flex;
    font-size: 1rem;
    cursor: pointer;
    color: rgb(28, 30, 33);
    height: 52px;
    align-items: center;
    padding-left: 1rem;
    border-left: 3px solid white;
    font-weight: 400;
}

.ConfigurationPage-sideleft-btn:hover {
    background-color: #eee;
    border-left: 3px solid #eee;
}

.ConfigurationPage-sideleft-btn.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    font-weight: 700;
    border-left: 3px solid var(--primary-color);
}

.ConfigurationPage-SubTitle {
    font-size: 1rem;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
}

.ConfigurationPage-Wrapper {
    display: flex;
    flex-direction: column;
    height: min-content;
    width: 100%;
    min-width: 400px;
}