.HorizontalField {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    justify-content: space-between;
    padding: 0.5rem 0rem;
    color: #333333;

}

.HorizontalField-Content {
    display: flex;
    justify-content: flex-end;
    word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
    text-align: right;
}