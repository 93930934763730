.NewCheckbox-input[type=checkbox]+.NewCheckbox-label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
    color: var(--input-color);
}

.NewCheckbox-p {
    color: var(--input-color);
}

.NewCheckbox-input[type=checkbox] {
    display: none;
}

.NewCheckbox-input[type=checkbox]+.NewCheckbox-label:before {
    content: "\2714";
    border: 0.1em solid var(--primary-bg);
    border-radius: 0.2em;
    display: inline-block;
    text-align: center;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2em;
    vertical-align: center;
    color: transparent;
    transition: .2s;
}

.NewCheckbox-input[type=checkbox]+.NewCheckbox-label:active:before {
    transform: scale(0);
}

.NewCheckbox-input[type=checkbox]:checked+.NewCheckbox-label:before {
    background-color: var(--primary-bg);
    border-color: var(--primary-bg);
    color: #fff;
}

.NewCheckbox-input[type=checkbox]:disabled+.NewCheckbox-label:before {
    transform: scale(1);
    border-color: #aaa;
}

.NewCheckbox-input[type=checkbox]:checked:disabled+.NewCheckbox-label:before {
    transform: scale(1);
    background-color: var(--primary-bgh);
    border-color: var(--primary-bgh);
}