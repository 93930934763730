.ThousandDaysPage-Wrapper {
	display: flex;
	flex-direction: column;
	height: min-content;
	min-width: 400px;
}

.ThousandDaysPage-Input {
	max-width: 360px;
}

.ThousandDaysPage-Tabs {
	margin-bottom: 1rem;
}

.ThousandDaysPage-accept-btn-box {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
}

.ThousandDaysPage-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}

.ThousandDaysPage-PatientsList {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ThousandDaysPage-BtnFullWidth button {
	width: 100%;
}

.ThousandDaysPage-Content {
	display: grid;
	grid-template-columns: 280px min-content 1fr;
	column-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.ThousandDaysPage-Separator {
	width: 1px;
	min-height: 45vh;
	background-color: #eee;
}

.ThousandDaysPage-Body {
	width: 100%;
}

.ThounsandDaysPageFilter-Separator {
	margin-top: 20px;
	width: 100%;
	min-height: 1px;
	background-color: #eee;
}

.ThousandDaysPage-Content-SideBarLeft {
	display: flex;
	flex-direction: column;
}

.ThousandDaysPage-Birthday-Select-Date {
	display: grid;
	column-gap: 8px;
	max-width: 100%;
	grid-template-columns: 80px 120px 80px;
}
