.component-tabs-headers {
    display: flex;
}

.component-tab-header {
    padding: 10px 10px 15px 10px;
    width: 100%;
    color: var(--primary-color);
    cursor: pointer;
    position: relative;
    top: 2px;
    border-bottom: 1px solid transparent;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 2;
    
}

.component-tab-header:hover {
    color: var(--color-page);
    border: 1px solid var(--light-borders);
    border-bottom: 2px solid white;
}

.component-tab-header.selected {
    color: var(--color-page);
    font-weight: bold;
    border: 1px solid var(--light-borders);
    border-bottom: 2px solid white;

}

.component-tabs-content {
    padding: 10px;
    border: 1px solid var(--light-borders);
    border-top-width: 2px;
}
