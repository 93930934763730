.LifeHabitsAndBehaviorChart {
    width: 500px;
    height: 300px;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
}

.LifeHabitsAndBehaviorChart-Grid-Chart-Container {
    position: relative;
    width: 500px;
    height: 300px;
    margin-bottom: 30px;
    cursor: pointer;
}

.LifeHabitsAndBehaviorChart-ChartOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(227, 240, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    opacity: 0;
    transition: opacity 0.3s;
    text-decoration: underline;
}

.LifeHabitsAndBehaviorChart-Grid-Chart-Container:hover .LifeHabitsAndBehaviorChart-ChartOverlay {
    opacity: 1;
}

.LifeHabitsAndBehaviorChart-Modal {
    width: 500px;
    position: relative;
    cursor: pointer;
}
