.ThousandDays_Consultation_Childbirth {
    overflow: hidden;
    display: grid;
    align-content: space-between;
}

.ThousandDaysSection-Childbirth-Separator {
    height: 1px;
    background-color: #EEE;
    margin: 1.5rem 0;
}

.Partograph-Header {
    display: grid;
    grid-template-columns: min-content min-content min-content min-content min-content;
    column-gap: 0.75rem;
    font-weight: 900;
    font-size: 0.95rem;
}

.Partograph-Header > p {
    white-space: nowrap;
    cursor: pointer;
    color: var(--primary-color-light-2);
}

.Partograph-Header .Partograph-Header-Active {
    color: var(--primary-color);
}


.ThousandDays_Consultation_Childbirth_Action {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.ThousandDaysChildBirth_mt {
    margin-top: 1rem;
}

.ThousandDaysSection-DatetimeRow-SelectRow {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 2rem;
}

.ThousandDaysSection-Childbirth-InfoHour {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: 92px 92px;
}

.ThousandDaysSection-Childbirth-Datetime {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: 5rem 7.5rem 5rem;
}

.ThousandDaysSection-Childbirth-TypeChildbirthBlooding {
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ThousandDaysChildBirth_subtitle {
    padding: 6px;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.ThousandDaysChildBirth_DrugParam {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 45px;
    column-gap: 1rem;
}

.ThousandDaysSection-MotherInfoSection-Input {
    width: 120px;
}

.ThousandDaysChildbirth_List div {
    margin-top: 1.5rem;
}

.ThousandDaysChildBirth_list_align {
    height: 2rem;
}

.ThousandDaysChildBirth_DrugParam_Btn {
    padding-top: 12px;
}

.ThousandDaysChildBirth_newborn_radiobtn {
    margin-top: 0.75rem;
}

.ThousandDaysSection-Childbirth-VitalSigns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
    margin-bottom: 1.5rem;
}

.ThousandDaysSection-Childbirth-Anthropometry {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;

}
.ThousandDaysSection-Childbirth-VitalSigns-Input {
    width: 100px;
}

.ThousandDaysChildBirth_APGAR_Title {
    margin-bottom: 1.5rem;
}

.ThousandDaysChildBirth_APGAR-MT {
    margin-top: 2rem;
}

.ThousandDaysChildBirth_APGAR .form-radiobutton > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.ThousandDaysChildBirth_APGAR .form-radiobutton > div > .radio-box {
    margin-left: 0;
}

.ThousandDaysSection-Childbirth-SaveBtn {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 1400px) {
    .ThousandDaysChildBirth_APGAR .form-radiobutton > div {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .ThousandDays_Consultation_Childbirth {
        grid-template-rows: unset;
    }

    .ThousandDaysChildBirth_DrugParam {
        grid-template-columns: 120px 120px 120px 45px;
    }
    .ThousandDaysChildbirth_List div {
        width: 100% !important;
    }
}

@media (max-width: 576px) {
    .ThousandDaysChildBirth_DrugParam {
        column-gap: 0.5rem;
        grid-template-columns: 1fr;
    }
    .ThousandDaysChildBirth_DrugParam_Btn {
        padding: 0;
        margin-bottom: 1rem;
    }
}