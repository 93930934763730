.NewEditHoldingTemplate {
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NewEditHoldingTemplate-Body {
    height: 320px;
    overflow-y: auto;
    padding-right: 0.75rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    overflow-y: auto;
}

.NewEditHoldingTemplate-Body::-webkit-scrollbar {
    width: 8px;
}

.NewEditHoldingTemplate-Body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.NewEditHoldingTemplate-Body::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 2px solid #f1f1f1; 
}

.NewEditHoldingTemplate-Body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-direction: column;
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow > div {
    display: grid;
    grid-template-columns: 1fr 28px 28px;    
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow-Name {
    font-size: 0.8rem;
    padding: 0.25rem;
    border-top: 2px solid var(--primary-color-light);
    border-left: 2px solid var(--primary-color-light);
    border-bottom: 2px solid var(--primary-color-light);
    padding-right: 0.75rem;
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: white;
    background-color: var(--primary-color-light);
    cursor: pointer;
    text-align: center;
    line-height: 0;
    font-size: 0.8rem;
    height: 27px;
}

.NewEditHoldingTemplate-NewSelect {
    padding: 0rem 0.25rem;
}

.NewEditHoldingTemplate-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow-Item {
    display: grid;
    grid-template-columns: auto 40px 28px; 
    align-items: center;
}

.NewEditHoldingTemplate-Select-SelectedOptionsRow-Arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 0.5rem;
}
  
.NewEditHoldingTemplate-Select-SelectedOptionsRow-Arrows button {
    background: #ddd;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;
    margin: 2px 0;
    border-radius: 4px;
}
  
.NewEditHoldingTemplate-Select-SelectedOptionsRow-Arrows button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.NewEditHoldingTemplate-Body-HeaderInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    flex-direction: row;
}

.NewEditHoldingTemplate-SectionsManager {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 172px 1px 1fr;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn {
    display: flex;
    font-size: 1rem;
    cursor: pointer;
    color: rgb(28, 30, 33);
    height: 52px;
    align-items: center;
    padding-left: 1rem;
    border-left: 3px solid white;
    font-weight: 400;
}

.NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn:hover {
    background-color: #eee;
    border-left: 3px solid #eee;
}

.NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    font-weight: 700;
    border-left: 3px solid var(--primary-color);
}

.NewEditHoldingTemplate-SectionsManager-Separator {
    width: 1px;
    background-color: #eee;
}

.NewEditHoldingTemplate-SectionsManager-OpenSection {
    height: 100%;
    overflow-y: auto;
}

.NewEditHoldingTemplate-Asterisk {
    color: rgb(236, 106, 106);
}

.NewEditHoldingTemplate-Select .SelectBox, .NewEditHoldingTemplate-Input .form-box {
    padding-top: 4px;
}
