.Duplicate-Modal{
    width: 50vw;
    height: 500px;
}

.Duplicate-Modal-Table-Container {
    max-height: 300px;
    overflow-y: auto;
  }

.Duplicate-Modal-Table {
    width: 100%;
    border-collapse: collapse;
}

.Duplicate-Modal-Table th, .Duplicate-Modal-Table td {
    padding: 10px 5px;
    text-align: center;
}

.Duplicate-Modal-Table-Title{
    font-weight: bold;
}

.Duplicate-Modal-Table-Head{
    background-color: rgb(184, 182, 182);
    color: black;
}

.Duplicate-Modal-Table-Actions{
    display: flex;
    gap: 5px;
}

.Duplicate-Modal-Button-1, .Duplicate-Modal-Button-2, .Duplicate-Modal-Button-3{
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.Duplicate-Modal-Button-1 {
    color: rgb(119, 119, 1);
    background-color: rgb(241, 241, 146);
    border: 1px solid rgb(119, 119, 1);
}

.Duplicate-Modal-Button-2 {
    color: rgb(6, 33, 185);
    background-color: rgb(191, 180, 241);
    border: 1px solid rgb(6, 33, 185);
}

.Duplicate-Modal-Button-3{
    color: rgb(185, 6, 6);
    background-color: rgb(241, 180, 180);
    border: 1px solid rgb(185, 6, 6);
}