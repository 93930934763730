.Menu {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  height: 100%;
}

.Menu div {
  display: flex;
  color: var(--primary-font-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 24px;
  cursor: pointer;
  height: 100%;
  border-bottom: 4px solid var(--primary-color);
}

.Menu div h5 {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.75rem;
  border-radius: 8px;
}

.MenuActive {
  border-bottom: 4px solid rgb(255, 255, 255, 0.2) !important;
}

.Menu div:hover h5, .MenuActive h5 {
  background-color: rgb(255, 255, 255, 0.2);
}
