.AppointmentInstance {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 8px;
}

.AppointmentInstance-Header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.AppointmentInstance-Header-ChangesBox {
    margin-top: 1.25rem;
}

.AppointmentInstance-Header-ChangesBox-Text {
    color: #bbb;
}

.AppointmentInstance-Header-TitleBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.AppointmentInstance-Header-TitleBox-PatientName {
    text-transform: uppercase;
    font-weight: bolder;
}

.AppointmentInstance-Header-TitleBox-AlertsBox {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}

.AppointmentInstance-Header-TitleBox-PriorityBox {
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
}

.AppointmentInstance-Header-AltText {
    font-size: 0.8rem;
    color: #aaa;
}

.AppointmentInstance-PatientBox-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    color: #aaa;
}

.AppointmentInstance-PatientBox-Grid {
    padding-left: 1rem;
    display: flex;
    column-gap: 0.5rem;
    color: #aaa;
}

.AppointmentInstance-PatientBox-Grid-AltText {
    font-size: 0.875rem;
    color: #aaa;
}

.AppointmentInstance-AppointmentBox-Grid {
    padding-left: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    color: #aaa;
}

.AppointmentInstance-AppointmentBox-Grid-AltText {
    font-size: 0.875rem;
    color: #aaa;
}

.AppointmentInstance-AppointmentBox-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    color: #aaa;
}

.AppointmentInstance-AppointmentBox-Subtitle-SpecialtyBox {
    border: 1px solid #aaa;
    background-color: #fbfbfb;
    color: #aaa;
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
}

.AppointmentInstance-AppointmentBox-Subtitle-DecisionBox {
    border: 1px solid var(--primary-color);
    background-color: #E3F0FF;
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bolder;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
}

.AppointmentInstance-ActionRow {
    margin-top: 1rem;
    display: flex;
    column-gap: 0.5rem;
}

.AppointmentInstance-ActionRow svg {
    font-size: 0.8rem;
    width: 14px;
    height: 14px;
}

.AppointmentInstance-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    color: #aaa;
    fill: #aaa;
}

.AppointmentInstance-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.AppointmentInstance-BtnBox {
    border-top: 1px solid #eee;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}