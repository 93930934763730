.ConcludeRegisterPage {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 450px;
    background-color: var(--background-container);
}

.ConcludeRegisterPage section {
    max-width: 450px;
    width: 100%;
    border: rgb(219, 219, 219);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 4rem 2rem 2rem;
    position: relative;
    background-color: var(--background-container);
    border-radius: 4px;
}


.ConcludeRegisterPage-Headline {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
}

.ConcludeRegisterPage-Headline > h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.Label-Asterisk{
    color: rgb(236, 106, 106);
}

.ConcludeRegisterPage-Title {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-bg);
}

.Label-Mandatory-Filling{
    color: rgb(236, 106, 106);
}

.ConcludeRegisterPage-CancelRegister {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    color: var(--text-color);
    background-color: var(--background-container);
    border-radius: 4px;
}

.ConcludeRegisterPage-CancelRegister span b {
    color: var(--primary-bg);
}

.ConcludeRegisterPage-CancelRegister span b:hover {
    cursor: pointer;
    color: var(--primary-bgh);
    text-decoration: underline;
}

.ConcludeRegisterPage-ChildBirthRow {
    color: var(--text-color);
}

.ConcludeRegisterPage-ChildBirthRow > div {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 95px auto 95px;
}

.ConcludeRegisterPage-RowCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.ConcludeRegisterPage-BtnBox {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.ConcludeRegisterPage-BtnBox button {
    width: 100%;
}

.info-toolTip{
    padding: 10px;
    max-height: 140px;
    width: 180px;
    position: absolute;
    top: -1.5rem;
    right: -12rem;
    background-color: #fff;
    border: 1px solid var(--primary-bg);
    color: var(--primary-bg);
    border-radius: 4px;
}

@media (max-width: 576px) {
    .ConcludeRegisterPage section {
        border: unset;
        box-shadow: unset;
    }
}