.ThousandDays-Consultation-MainBoard {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 0.5rem;
}

.ThousandDays-Consultation-TabWrapper {
    overflow: auto;
    min-height: 50vh;
    height: min-content;
    margin-bottom: 1rem;
}

.ThousandDays-Consultation-TabWrapper-Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72px;
    margin-top: auto;
}

.ThousandDays-Consultation-TabWrapper-Footer-1000days {
    color: #CED4DA;
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.ThousandDays-Consultation-TabWrapper-LogoWrapper {
    display: flex;
    justify-content: center;
  }
  
.ThousandDays-Consultation-TabWrapper-LogoWrapper > img {
    width: 92px;
}

.ThousandDays-Consultation-TabWrapper-VerticalSeparator {
    width: 0.5px;
    background-color: #fff;
    margin: 0rem 0.5rem;
    height:  32px;
}

.ThousandDays-Consultation-MainBoard-Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.ThousandDays-Consultation-MainBoard-Header-PatientCard  {
    color: #CED4DA;
}