.InventoryMaterials {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.InventoryMaterials-Header {
    display: grid;
    grid-template-columns: min-content min-content min-content 1fr;
    width: 100%;
}

.InventoryMaterials-Header > div {
    padding: 1rem;
    cursor: pointer;
    border: 1px solid white;
    border-bottom: 2px solid #eee;
    color: var(--primary-color-lighter);
}

.InventoryMaterials-Header > div.active, .InventoryMaterials-Header-Option:hover {
    border: 1px solid #eee;
    border-bottom: 2px solid white;
    border-radius: 4px 4px 0px 0px;
    color: var(--color-page);
}

.InventoryMaterials-Content {
    border: 1px solid #eee;
    border-top: 1px solid white;
}