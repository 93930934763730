.AttendanceHistorySection-subtitle {
    font-size: bold;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.resultsTab-modal {
    overflow-y: auto;
    min-width: 250px;
}

.AttendanceHistorySection-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}