.HomePage-Header {
    background-color: var(--primary-color);
    width: 100%;
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    padding: 0.75rem 1rem;
}

.HomePage-Header-Slot {
    height: 100%;
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 0.5rem;
    align-items: center;
    color: var(--primary-font-color)
}

.HomePage-Header-Slot-Info span {
    color: rgb(var(--primary-font-color), 0.8);
}

.HomePage-Header-Slot-IconBox {
    height: 48px;
    background-color: rgb(255, 255, 255, 0.2);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomePage-Dashboard {
    display: grid;
    grid-template-areas: 
    "AppointmentHistory AppointmentsToday"
    "LastAppointments AppointmentsToday";
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    border-radius: 0.5rem;
    justify-content: space-evenly;
    grid-template-columns: calc(72vw - 200px) minmax(350px, 28vw);
    grid-template-rows: minmax(360px, 1fr) minmax(320px, 1fr);
    margin-top: 1rem;
}

.HomePage-Dashboard-Card {
    border: 1px solid #eee;
    padding: 1rem;
}

.HomePage-Dashboard-Card-Separator {
    background-color: #eee;
    height: 1px;
}

.HomePage-Dashboard-AppointmentHistory {
    grid-area: AppointmentHistory;
}

.HomePage-Dashboard-AppointmentsToday {
    grid-area: AppointmentsToday;
}

.HomePage-Dashboard-LastAppointments {
    grid-area: LastAppointments;    
    display: grid;
    grid-template-rows: 1fr min-content;
}

.HomePage-Dashboard-LastAppointments-Footer {
    height: max-content;
    justify-self: center;
    text-align: center;
    display: flex;
    align-items: center;
    height: 40px;
}

.HomePage-Dashboard-LastAppointments-Footer:hover {
    text-decoration: underline;
    cursor: pointer;
}
