.ChatList-Item {
    width: 100%;
    padding: 18px 12px;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: background-color 0.3s ease;
    overflow: visible;
}

.ChatList-Item:hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
    background-color: #e4e4e4;
}

.ChatList-Item-LeftInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ChatList-Item-IconDiv {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    position: relative;
}

.ChatList-Item-IconDiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.ChatList-Item-Status {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    position: absolute;
    bottom: 3px;
    right: -3px;
    border: 2px solid #fff;
    
}

.online {
    background-color: rgb(18, 224, 18);;
}

.offline {
    background-color: gray;
}

.ChatList-Item-Name {
    margin: 0 30px 0 0;
    white-space: normal; 
    /* word-wrap: break-word;  */
    /* word-break: break-word;  */
    /* overflow-wrap: break-word; */
    text-overflow: ellipsis;
    overflow: hidden;
}


.ChatList-Item-Img{
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.ChatList-Item-UnreadMessages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--primary-color);
    position: absolute;
    right: 35px;
}

.ChatList-Item-UnreadMessages-Number {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: small;
    line-height: 0;
}

.ChatList-Item:hover .ChatList-Item-MoreOptions-IconDiv {
    opacity: 0.5;
}

.ChatList-Item-MoreOptions-IconDiv {
    width: 1.125rem;
    height: 1.125rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
    flex-shrink: 0;
    opacity: 0;
    transition: background-color 0.3s ease;
    border-radius: 50%;
}

.ChatList-Item-MoreOptions-IconDiv:hover {
    background-color: #bbb;
}

.ChatList-Item-MoreOptions {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #aaa;
    position: absolute;
    right: 35px;
    z-index: 2;
    box-sizing: border-box;
}

.ChatList-Item-MoreOptions {
    top: 10px; /* valor padrão */
  }
  
  .ChatList-Item-MoreOptions.group {
    top: -20px; 
  }
  
.ChatList-Item-MoreOptions p {
    margin: 0;
    padding: 0.625rem 1rem 0.625rem 1rem;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}


.ChatList-Item-MoreOptions p:hover {
    width: 100%;
    background-color: #e4e4e4;
}