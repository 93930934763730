.EmailVerified {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 450px;
    background-color: var(--background-container);
}

.EmailVerified > section > h3 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-bg);
}

.EmailVerified section {
    max-width: 400px;
    width: 100%;
    border: rgb(219, 219, 219);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 4rem 2rem 2rem;
    position: relative;
    background-color: var(--background-container);
    border-radius: 4px;
}

.EmailVerified-Headline {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
}

.EmailVerified-Headline > h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.EmailVerified-Link {
    cursor: pointer;
}

.EmailVerified-Link:hover {
    color: var(--primary-color);
}