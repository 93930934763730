.ConsultationManager {
    border-top: 1px solid #eee;
    padding-top: 0.25rem;
    position: relative;
}

.ConsultationManager-Body {
    padding-top: 0.75rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 172px 1px 1fr;
    position: relative;
}

.ConsultationManager-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(227, 240, 255, 0.5); 
    z-index: 10;
    pointer-events: none; 
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem; 
    color: var(--primary-color);
    text-decoration: underline;

}

.ConsultationManager:hover .ConsultationManager-overlay {
    opacity: 1; 
    pointer-events: all; 
}

.ConsultationManager-separator {
    width: 1px;
    min-height: 45vh;
    background-color: #eee;
}

.ConsultationManager-openSection {
    height: 100%;
    overflow-y: auto;
}

.ConsultationManager-sideleft-btn {
    display: flex;
    font-size: 1rem;
    cursor: pointer;
    color: rgb(28, 30, 33);
    height: 52px;
    align-items: center;
    padding-left: 1rem;
    border-left: 3px solid white;
    font-weight: 400;
}

.ConsultationManager-sideleft-btn:hover {
    background-color: #eee;
    border-left: 3px solid #eee;
}

.ConsultationManager-sideleft-btn.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    font-weight: 700;
    border-left: 3px solid var(--primary-color);
}

.consultation-save-toast {
    width: fit-content;
    position: relative;
}

.ConsultationManager-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.ConsultationManager-footer-lastsave {
    display: flex;
    fill: #14A44D;
    color: #14A44D;
}

.ConsultationManager-footer-lastsave > svg {
    margin-right: 0.5rem;
}

.ConsultationManager-footer-lastsave > svg > g {
    fill: #14A44D;
}

.ConsultationManager-button-actions {
    display: flex;
    margin-top: 1rem;
}
