.InventoryMenuPage {
    display: grid;
    grid-template-columns: 120px 1fr;
    column-gap: 2rem;
    height: 100%;
    flex-grow: 1;
}

.InventoryMenuPage-Action-SelectorBtn {
    display: flex;
    font-size: 1rem;
    cursor: pointer;
    color: rgb(28, 30, 33);
    height: 52px;
    align-items: center;
    padding-left: 1rem;
    border-left: 3px solid white;
    font-weight: 400;
}

.InventoryMenuPage-Action-SelectorBtn:hover {
    background-color: #eee;
    border-left: 3px solid #eee;
}

.InventoryMenuPage-Action-SelectorBtn.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    font-weight: 700;
    border-left: 3px solid var(--primary-color);
}

.InventoryMenuPage-Wrapper {
    display: flex;
    height: min-content;
    width: 100%;
    min-width: 400px;
}