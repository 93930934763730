.PregnancyRiskCalculator-Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-bottom: 1.5rem;
}

.PregnancyRiskCalculator-Header {
    display: grid;
    grid-template-columns: min-content min-content min-content min-content;
    column-gap: 1rem;
    font-weight: 900;
    font-size: 0.95rem;
}

.PregnancyRiskCalculator-Header > p {
    cursor: pointer;
    color: var(--primary-color-light-2);
    white-space: nowrap;
}

.PregnancyRiskCalculator-Header .PregnancyRiskCalculator-Header-Active {
    color: var(--primary-color);
}

.PregnancyRiskCalculator_Form {
    overflow-y: auto;
    overflow-x: hidden;
}

.PregnancyRiskCalculator-subtitle {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.PregnancyRiskCalculator-Checkbox > form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
}

.PregnancyRiskCalculator-NutritionRow {
    margin-top: 2rem; 
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: 120px 120px 200px;
}

.PregnancyRiskCalculator-ScoreWrapper {
    margin-top: 2rem;
}

.PregnancyRiskCalculator-2FR {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 1rem;
}

.PregnancyRiskCalculator-2FR div > b {
    white-space: nowrap;
}

.PregnancyRiskCalculator-3FR-Inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.75rem;
    width:  min-content;
}

.PregnancyRiskCalculator-3FR-Inputs div > b {
    white-space: nowrap;
}

.PregnancyRiskCalculator-DefaultInput {
    width: 132px;
}

.PregnancyRiskCalculator-4FR {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.75rem;
    width: min-content;
}

.PregnancyRiskCalculator-4FR div > b {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .PregnancyRiskCalculator-Checkbox > form {
        grid-template-columns: 1fr;
    }
}
