.EditLocationsOptionsModal {
    width: 500px;
}

.EditLocationsOptionsModal-CursorContainer {
    display: grid;
    grid-template-columns: 1fr min-content;
    column-gap: 1rem;
    margin-bottom: 1rem;
}

.EditLocationsOptionsModal-CursorContainer .form-box {
    margin-bottom: 0px;
}

.EditLocationsOptionsModal-CursorContainer-SaveBtn {
    margin-top: auto;
}

.EditLocationsOptionsModal-Body-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 360px;
}

.EditLocationsOptionsModal-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
}

.EditLocationsOptionsModal-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.EditLocationsOptionsModal-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.ConfirmLocationLookalikeSavingModal-CallToAction {
    margin-top: 2rem;
}

.ConfirmLocationLookalikeSavingModal-BtnBox {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-top: 1rem;
}