@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@500&family=Noto+Sans+JP:wght@400;500&family=Noto+Sans+KR:wght@500&family=Open+Sans:wght@400;500&display=swap");


.dropdownFlag button {
    background-color: #eee;
    width: min-content;
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdownFlag-content {
  display: none;
  position: absolute;
  margin: 1px 0 0 0;
  padding: 4px 8px;
  background-color: #f5f5f5;
  width: min-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow-y: scroll;
  animation: slideIn 0.3s ease-out;
}

.dropdownFlag-content li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdownFlag button img,
.dropdownFlag-content li img {
  width: 32px; 
  height: auto; 
  margin-right: 5px; 
}


.dropdownFlag:focus-within .dropdownFlag-content {
  display: block;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
