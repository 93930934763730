.PreceptorshipDetailRoom-Loading {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PreceptorshipDetailRoom-Header {
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    background-color: var(--primary-color);
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 0.5rem;
    flex-wrap: wrap;
}

.PreceptorshipDetailRoom-Header-SlotBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    width: fit-content;
}

.PreceptorshipDetailRoom-Header-SlotBox-Slot {
    width: 240px;
    height: 100%;
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 0.5rem;
    align-items: center;
    color: white;
    font-size: 14px;
}

.PreceptorshipDetailRoom-Header-SlotBox-Slot-Info span {
    color: rgb(255, 255, 255, 0.8);
}

.PreceptorshipDetailRoom-Header-SlotBox-Slot-IconBox {
    height: 48px;
    background-color: rgb(255, 255, 255, 0.2);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PreceptorshipDetailRoom-Header-ActionBox {
    display: flex;
    column-gap: 0.5rem;
}

.PreceptorshipDetailRoom-Header-ActionBox > div {
    width: 52px;
    height: 52px;
    border-radius: 0.25rem;
    background-color: rgb(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.PreceptorshipDetailRoom-Header-ActionBox-Icon:hover .PreceptorshipDetailRoom-Header-ActionBox-Icon-Svg {
    fill: rgb(255, 255, 255, 0.8);
}

.PreceptorshipDetailRoom-Header-ActionBox-Icon-Svg {
    width: 14px;
    fill: white;
}

.PreceptorshipDetailRoom-Separator {
    height: 1px;
    background-color: #eee;
    margin: 1rem 0;
}

.PreceptorshipDetailRoom-Content-Header {
    display: flex;
    margin: 0.5rem 0;
}

.PreceptorshipDetailRoom-Content-Header-Btn {
	width: 200px;
	display: flex;
	font-size: 1rem;
	cursor: pointer;
	color: rgb(28, 30, 33);
	height: 52px;
	align-items: center;
	border-bottom: 1px solid #eee;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	color: #333333;
}

.PreceptorshipDetailRoom-Content-Header-Btn:hover {
	background-color: #eee;
	border-bottom: 1px solid #eee;
}

.PreceptorshipDetailRoom-Content-Header-Btn.active {
	color: var(--primary-color);
	fill: var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
}