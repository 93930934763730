.HealthProfileSection-TabManager {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
}

.HealthProfileSection-TabManager-btn {
  flex: 1 1 160px;
  max-width: 160px;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
  color: rgb(28, 30, 33);
  height: 40px;
  align-items: center;
  font-weight: 400;
  display: flex;
  justify-content: center;
  font-weight: 700;
  color: #333333;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
}

.HealthProfileSection-TabManager-btn:hover {
  border-bottom: 2px solid var(--primary-color);
}

.HealthProfileSection-TabManager-btn.active {
  color: var(--primary-color);
  fill: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.HealthProfileSection-Content {
  padding: 1rem 0;
}

.HealthProfileSection-TabContent {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
