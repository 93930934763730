.ToggleAppointmentField-NewMultiSelect {
    width: 400px;
}

.ToggleAppointmentField-NewMultiSelect-Subtitle {
    color: rgb(36, 37, 46);
    font-weight: bold;
    font-size: 14px;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.ToggleAppointmentField-NewMultiSelect-Subtitle svg {
    width: 20px;
    cursor: pointer;
    fill: #aaa;
    position: relative;
    top: 2px;
}

.ToggleAppointmentField-NewMultiSelect-Subtitle svg:hover {
    fill: var(--primary-bg);
}

.ToggleAppointmentField-NewMultiSelect-FilterNode {
    padding: 0rem 0.25rem;
}

.ToggleAppointmentField-NewMultiSelect-Selecteds {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}

.ToggleAppointmentField-NewMultiSelect-Selecteds-Option {
    cursor: pointer;
}

.ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
}

.ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow > div {
    display: grid;
    grid-template-columns: 1fr 28px;

}

.ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow-Name {
    font-size: 0.8rem;
    padding: 0.25rem;
    border-top: 2px solid var(--primary-color-light);
    border-left: 2px solid var(--primary-color-light);
    border-bottom: 2px solid var(--primary-color-light);
    padding-right: 0.75rem;
}

.ToggleAppointmentField-NewMultiSelect-SelectedOptionsRow-CloseBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: white;
    background-color: var(--primary-color-light);
    cursor: pointer;
    text-align: center;
    line-height: 0;
    font-size: 0.8rem;
}