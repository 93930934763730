.NewEditPhysicalAssessment {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 500px;
}

.NewEditPhysicalAssessment-Grid {
    display: flex;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}

.NewEditPhysicalAssessment-Select .SelectBox, .NewEditPhysicalAssessment-Input .form-box {
    padding-top: 8px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.NewEditPhysicalAssessment-Select div, .NewEditPhysicalAssessment-Input div {
    width: 180px;
}

.NewEditPhysicalAssessment-SkinBox {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.NewEditPhysicalAssessment-SkinBox-Grid {
    display: flex;
    column-gap: 2rem;
}

.NewEditPhysicalAssessment-PhysicalMobilityBox {
    display: flex;
    column-gap: 2rem;
}

.NewEditPhysicalAssessment-BtnBox {
    display: flex;
    justify-content: flex-end;
}
