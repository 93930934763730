.CouncilManager-Subtitle {
    color: var(--text-color);
    font-weight: 700;
}

.CouncilManager-AltText {
    font-size: 12px;
    font-weight: 400;
}

.CouncilManager-mt {
    margin-top: 1.5rem;
}

.CouncilManager-RowCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.CouncilManager-CRMCol {
    width: 400px;
    display: grid;
    grid-template-columns: 3fr 4fr min-content;
    column-gap: 0.5rem;
}

.CouncilManager-CRMCol-Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
}

.CouncilManager-Actions > button:nth-child(2) {
    margin-left: 0.5rem;
}

.CouncilManager-Header {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    margin-bottom: 2rem;
}

.CouncilManager-Header-Icon {
    -webkit-box-align: center;
    background-color: #1bbb86;
    border-color: #1bbb86;
    border-radius: 8px;
    border-style: none;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    fill: white;
    display: flex;
    flex-direction: column;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    text-size-adjust: 100%;
    text-underline-position: from-font;
    width: 28px;
    z-index: 2;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
}

.CouncilManager-Header-Title {
    font-weight: 600;
}

.CouncilManager-BtnFullWidth button {
    width: 100%;
}

.CouncilManager-Table {
    border-collapse: collapse;
    width: 100%;
}

.CouncilManager-TableHead {
    background-color: #f4f4f4;
    text-align: left;
}

.CouncilManager-TableCell {
    padding: 12px 15px;
    text-align: left;
    color: #333;
}

.CouncilManager-BtnBox {
    margin-top: 10px;
    width: fit-content;
}

.CouncilManager-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
    color: #333333;
    fill: #333333;
}

.CouncilManager-Btn:hover{
    background-color: #eee;
    border: 1px solid #eee;
}

.CouncilManager-ErrorModal {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
    min-width: 250px;
}

@media (min-width: 600px) {
    .CouncilManager-ErrorModal {
        width: 550px;
    }
}

.CouncilManager-ErrorModal li {
    margin: 8px 0;
}

.CouncilManager-ErrorModal label {
    font-weight: bold;
}