.RoutineReportsPageModal {
    width: 500px;
    height: 500px;
}

.RoutineReportsPageModal-Loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.RoutineReportsPageModal-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 148px);
}

.RoutineReportsPageModal-Content {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 1rem;
	height: 100%;
	flex-grow: 1;
}

.RoutineReportsPageModal-Separator {
	width: 100%;
	height: 1px;
	background-color: #bbb;
}

.RoutineReportsPageModal-Body {
	width: 100%;
}

.RoutineReportsPageModal-ReportUnitWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding-bottom: 1rem;
}

.RoutineReportsPageModal-ReportUnitWrapper-Unit {
    display: flex;
    justify-content: space-between;
    border: 1px solid #bbb;
    padding: 1rem;
    border-radius: 8px;
}

.RoutineReportsPageModal-ReportUnitWrapper-Unit-FirstBox {
	display: flex;
	flex-direction: column;
	row-gap: 0.25rem;
	font-size: 0.8rem;
}

.RoutineReportsPageModal-ReportUnitWrapper-Unit-Subtitle {
	text-transform: uppercase;
}

.RoutineReportsPageModal-NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
	fill: #bbb;
	color: #bbb;
	height: 40vh;
}

.RoutineReportsPageModal-NotFound > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 1rem;
	padding: 3rem;
}

.RoutineReportsPageModal-NotFound svg {
	width: 52px;
	height: 52px;
}

.RoutineReportsPageModal-NotFound p {
	font-size: 1rem;
}

.RoutineReportsPageModal-ActionRow {
	margin: 0.5rem 0;
    display: flex;
    align-items: center;
	column-gap: 0.5rem;
    height: 20px;
    margin-bottom: 1.5rem;
}

.RoutineReportsPageModal-ActionRow > div {
	border: 2px solid #bbb;
	border-radius: 12px;
	padding: 6px 8px;
	cursor: pointer;
}

.RoutineReportsPageModal-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    height: 20px;
}

.RoutineReportsPageModal-ActionRow > div:hover {
	border: 2px solid var(--primary-color);
}

.RoutineReportsPageModal-ActionRow > div:hover > svg {
    fill: var(--primary-color);
}
