.Collaborators-List {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Collaborators-ActionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
}

.Collaborators-ActionRow > div > svg {
    width: 20px;
    fill: #bbb;
    cursor: pointer;
    height: 20px;
}

.Collaborators-ActionRow > div > svg:hover {
    fill: var(--primary-color);
}

.Collaborator-Content {
	display: grid;
	grid-template-columns: 296px min-content 1fr;
	column-gap: 2rem;
	height: 100%;
	flex-grow: 1;
}

.Collaborator-Separator {
	width: 1px;
	min-height: 45vh;
	background-color: #eee;
}

.Collaborator-Body {
	width: 100%;
}

.PatientsFilter-Separator {
	margin-top: 20px;
	width: 100%;
	min-height: 1px;
	background-color: #eee;
}

.Collaborator-BtnFullWidth button {
	width: 100%;
}

.Collaborator-Content-SideBarLeft {
	display: flex;
	flex-direction: column;
}

.Collaborator-Content-SideBarLeft-BtnBox {
	margin-top: 1rem;
}

.Collaborator-List {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Collaborator-Birthday-Select-Date {
	display: grid;
	column-gap: 8px;
	max-width: 100%;
	grid-template-columns: 80px 120px 80px;
}