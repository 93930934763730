.list {
    padding: 5px 0 15px 0;
}

.list-headers{
    background-color: var(--primary-color);
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.list-headers .header {
    padding: 10px;
    font-weight: bold;
    color: white;
}

.list-headers, .list-row{
    display: flex;
}

.list-headers, .list-lines .header {
    width: 100%;
}

.list-value {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
}

