.InitSubSection {
    margin-top: 1rem;
}

.InitSubSection-PregnancyBackground-Details {
    margin-top: 1rem;
}

.InitSubSection-Separator {
    background-color: #eee;
    height: 1px;
    width: 100%;
    margin-bottom: 0.75rem;
}

.InitSubSection-Alerts {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}


.InitSubSection-GestationAgeRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.InitSubSection-AlertsRow {
    display: flex;
    column-gap: 0.5rem;
}

.InitSubSection-PregnancyAge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-top: 1rem;
    color: #80a6e5;
    width: fit-content;
    border: 1px solid #80a6e5;
    height: 48px;
    padding: 0 0.75rem;
    border-radius: 4px;
    background-color: #f9fbfe;
}

.InitSubSection-PregnancyAge p {
    
    display: flex;
    align-items: center;
}

.InitSubSection-PregnancyAge-Icon {
    margin-right: 6px;
}

.InitSubSection-CTA-UltrassomExam {
    cursor: pointer;
    font-weight: bold;
    color: #bbb;
}

.InitSubSection-CTA-UltrassomExam:hover {
    text-decoration: underline;
}

.InitSubSection-CTA {
    cursor: pointer;
}

.InitSubSection-CTA:hover {
    text-decoration: underline;
}