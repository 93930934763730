.HealthPlaceBranches {
    width: 100%;
}

.HealthPlaceBranches-FilterRow {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 0.5rem;
    max-width: 600px;
}

.HealthPlaceBranches-List {
    margin-top: 2.5rem;
}

.HealthPlaceBranches-RegisterCollaborator {
    max-width: fit-content;
    max-width: 1000px;
}

.HealthPlaceBranches-BtnBox {
    display: flex;
    column-gap: 0.5rem;
}