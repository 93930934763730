.PatientUnit {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 8px;
}

.PatientUnit-Header {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.PatientUnit-Header-Duplicate-Button{
    cursor: pointer;
    color: red;
    font-size: 15px;
    padding: 5px;
    background-color: rgb(255, 192, 192);
    border: red 1px solid;
    border-radius: 5px;
}

.PatientUnit-Header-Profile {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.PatientUnit-Header-Profile-Avatar {
    display: flex;
    flex-direction: row;
}

.PatientUnit-Header-Profile-Avatar > .img {
    border: 1px solid #eee;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.PatientUnit-Header-Profile-Avatar > .img > .icon-user {
  font-size: 1.5rem;
  color: var(--primary-bg);
}

.PatientUnit-Header-Profile-Avatar-Img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.PatientUnit-Header-Profile-PatientInfoBox {
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
}    

.PatientUnit-Header-Profile-PatientInfoBox-Name {
    text-transform: uppercase;
}

.PatientUnit-Header-Profile-PatientInfoBox-AltText {
    font-size: 0.8rem;
    color: #aaa;
}

.PatientUnit-PersonalData {
    padding-left: 0.5rem;
}

.PatientUnit-PersonalData-Subtitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.PatientUnit-PersonalData-Grid {
    padding-left: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
}

.PatientUnit-PersonalData-Grid-AltText {
    font-size: 0.875rem;
    color: #333333;
}

.PatientUnit-ActionRow {
    display: flex;
    column-gap: 1rem;
}

.PatientUnit-ActionRow svg {
    font-size: 0.8rem;
}

.PatientUnit-ActionRow-Btn {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 4px;
}

.PatientUnit-ActionRow-Btn:hover {
    background-color: #eee;
    border: 1px solid #eee;
}

.PatientUnit-Inactive {
    opacity: 50%;
}