.PreceptorshipEvaluation-EvaluationTable {
    display: grid;
    grid-template-columns: minmax(auto, 420px) 1fr;
    column-gap: 2rem;
    margin-top: 1rem;
}

.PreceptorshipEvaluation-Table {
    width: 100%;
    border: 1px solid var(--primary-color);
    table-layout: fixed;
    height: min-content;
}

.PreceptorshipEvaluation-Table th:nth-child(2),
.PreceptorshipEvaluation-Table th:nth-child(3),
.PreceptorshipEvaluation-Table td:nth-child(2),
.PreceptorshipEvaluation-Table td:nth-child(3) {
    width: 120px; 
}

.PreceptorshipEvaluation-Table th {
    border: 1px solid #D9E4E6;
    background-color: var(--primary-color);
    padding: 0.5rem;
    color: white;
    
}

.PreceptorshipEvaluation-Table td {
    border: 0.001px solid #eef7ff;
}

.PreceptorshipEvaluation-Table tr td:first-child {
    padding-left: 0.75rem;
}

.PreceptorshipEvaluation-Table-OddRow > td {
    background-color: #eef7ff;
}
 
.PreceptorshipEvaluation-Table-Radio {
    border: 1px solid #bbb;
    border-radius: 50%;
    height: 20px;
    transition: all 0.3s;
    width: 20px;
    margin: auto;
    cursor: pointer;
}

.PreceptorshipEvaluation-Table-Radio.filled {
    background-color: var(--primary-color);
}

.PreceptorshipEvaluation-Table-Input {
    padding: 0.2rem 0rem;
    padding-left: 0.2rem;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    margin: 0;
    text-align: left; 
    appearance: none;
}

.PreceptorshipEvaluation-Table-Input:hover {
    border-color: #888;
}

.PreceptorshipEvaluation-Table-Input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.PreceptorshipEvaluation-Table-Grid line {
    stroke: var(--primary-color);
}

.PreceptorshipEvaluation-EvaluationTable-BtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
}