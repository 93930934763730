.PatientExistentHandler {
    width: 380px;
}

.PatientExistentHandler-RadioManager {
    margin-top: 1rem;
}

.PatientExistentHandler-BtnBox {
    margin-top: 1rem;
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
}